import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import { useMaturityLevel } from '../../component-maturity.js';

const handleClick = props => e => {
  if (props.disabled || props.loading) {
    _.invoke(e, 'preventDefault');
    return;
  }
  _.invoke(props, 'onClick', e, props);
};

/** Renders a button, for clicking */
const Button = (props) => {
  useMaturityLevel('proven', 'Button');
  const {
    as: ElementType,
    children,
    caret,
    open,
    cog,
    className,
    disabled,
    forwardedRef,
    loading,
    onClick,
    selected,
    primary,
    secondary,
    size,
    tertiary,
    ...rest
  } = props;
  const weight =
    (primary && 'primary') ||
    (secondary && 'secondary') ||
    (tertiary && 'tertiary') ||
    'secondary';

  const cogOnly = () => !caret && !!cog && !_.some(children);
  const cogClasses = (base) => classnames(`${base}`, {
    [`${base}--cog-only-open`]: cogOnly() && !!open,
    [`${base}--cog-only-closed`]: cogOnly() && !open,
    [`${base}--cog-only`]: cogOnly()
  });

  const buttonClasses = classnames(
    'particles-button',
    `particles-button--${weight}`,
    `particles-button--${size}`,
    cogClasses('particles-button'),
    {
      'particles-button--selected': !!selected,
      [`particles-button--${weight}-loading`]: !!loading,
      [`particles-button--${weight}-disabled`]: !!disabled,
      'particles-button--loading': !!loading
    },
    className
  );
  const caretClasses = () => classnames('particles-button__caret', {
    'particles-button__caret--closed': !open, 'particles-button__caret--open': !!open
  });
  return (
    <ElementType
      ref={forwardedRef}
      className={buttonClasses}
      disabled={disabled}
      onClick={handleClick(props)}
      {...rest}
    >
      {cog && <i className={`fa fa-cog ${cogClasses('particles-button__cog')}`} />}
      {children}
      {caret && <i className={`fa fa-chevron-down ${caretClasses(open)}`} aria-hidden="true" />}
    </ElementType>
  );
};

const WithRef = forwardRef((props, ref) => (<Button {...props} forwardedRef={ref} />));

Button.propTypes = {
  /** String or function to transform the component into. */
  as: (...args) => PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType
  ])(...args),
  /** include a cog icon */
  cog: PropTypes.bool,
  /** include a caret icon */
  caret: PropTypes.bool,
  /** open/closed state (only applies to caret) */
  open: PropTypes.bool,
  /** Child components */
  children: PropTypes.node,
  /** Additional class to apply to component */
  className: PropTypes.string,
  /** Whether the button should render as disabled */
  disabled: PropTypes.bool,
  /** Whether to show loading state */
  loading: PropTypes.bool,
  /** Function to execute when component is clicked */
  onClick: PropTypes.func,
  /** Size of the button ['compact', 'large', 'regular'] */
  size: PropTypes.oneOf(['compact', 'large', 'regular']),
  /** Applies selected styling to component (only makes sense inside a button group) */
  selected: PropTypes.bool,
  /** Applies primary weight styling to component */
  primary: PropTypes.bool,
  /** Applies secondary weight styling to component */
  secondary: PropTypes.bool,
  /** Applies tertiary weight styling to component */
  tertiary: PropTypes.bool
};

Button.defaultProps = {
  as: 'button',
  className: '',
  disabled: false,
  loading: false,
  onClick: _.noop,
  selected: false,
  primary: false,
  secondary: false,
  size: 'regular',
  tertiary: false
};

Button.displayName = 'Button';
Button.WithRef = WithRef;

export default Button;

export const {
  propTypes,
  defaultProps,
  displayName
} = Button;
