import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TableWrapper = ({ className, compact, ...props }) => {
  const classes = classnames('particles-table__wrapper', className, {
    'particles-table__wrapper--compact': !!compact
  });
  return <div className={classes} {...props} />;
};

TableWrapper.propTypes = {
  /** Reducing spacing to make table smaller */
  compact: PropTypes.bool
};

TableWrapper.defaultProps = {};

TableWrapper.displayName = 'Table.Wrapper';

export default TableWrapper;

export const { propTypes, defaultProps, displayName } = TableWrapper;
