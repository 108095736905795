import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { NavigationTypeContext, useNavigationType } from '../context.js';
import { useMaturityLevel } from '../../../component-maturity.js';


const disabledProps = (disabled) => (disabled ? {
  disabled, onClick: event => _.invoke(event, 'preventDefault')
} : {});

const Link = (props) => {
  useMaturityLevel('stable', 'Navigation.Link');
  const { as: Component, active, className, disabled, ...rest } = props;
  const navigationType = useNavigationType(props);
  const linkClasses = classnames(
    'particles-navigation-link',
    `particles-navigation-link--${navigationType}`,
    className, {
      'particles-navigation-link--disabled': !!disabled,
      'particles-navigation-link--active': !!active,
      [`particles-navigation-link--${navigationType}-disabled`]: !!disabled,
      [`particles-navigation-link--${navigationType}-active`]: !!active
    }
  );
  return (
    <NavigationTypeContext {...props}>
      <Component className={linkClasses} {..._.omit(rest, ['footer', 'primary', 'context', 'utility'])} {...disabledProps(disabled)} />
    </NavigationTypeContext>
  );
};

Link.defaultProps = {
  footer: false,
  primary: false,
  utility: false,
  active: false,
  as: 'a'
};
Link.propTypes = {
  /** Disable Link, ignores clicks and uses disabled styling */
  disabled: PropTypes.bool,
  /** Enable primary styling */
  primary: PropTypes.bool,
  /** Enable utility styling */
  utility: PropTypes.bool,
  /** Enable footer styling */
  footer: PropTypes.bool,
  /** Enable active styling */
  active: PropTypes.bool,
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div', 'section', 'nav' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Link.displayName = 'Navigation.Link';

export default Link;

export const {
  defaultProps,
  propTypes,
  displayName
} = Link;
