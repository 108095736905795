import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from '../PageContainer/index.js';

const MainBodySection = ({ className, title, action, children, compact, popover, ...rest }) => {
  const classes = classnames(
    'particles-page__main-body-section',
    className,
    {
      'particles-page__main-body-section--compact': !!compact,
      'particles-page__main-body-section--popover': !!popover
    }
  );
  const contentClasses = classnames(
    'particles-page__main-body-section-content',
    {
      'particles-page__main-body-section-content--compact': !!compact,
      'particles-page__main-body-section-content--popover': !!popover
    }
  );
  const titleClasses = classnames(
    'particles-page__main-body-section-title',
    {
      'particles-page__main-body-section-title--compact': !!compact,
      'particles-page__main-body-section-title--popover': !!popover
    }
  );

  const metaClasses = classnames(
    'particles-page__main-body-section-meta',
    {
      'particles-page__main-body-section-meta--compact': !!compact,
      'particles-page__main-body-section-meta--popover': !!popover
    }
  );


  return (
    <section className={classes} {...rest}>
      <Container noWrap>
        {title && (
          <div className={metaClasses}>
            {title && (
              <h3 className={titleClasses}>
                {title}
              </h3>
            )}
          </div>
        )}
        {action && (
          <div className="particles-page__main-body-section-actions">
            {action}
          </div>
        )}
        {children && (
          <div className={contentClasses}>
            {children}
          </div>
        )}
      </Container>
    </section>
  );
};

MainBodySection.propTypes = {
  /** Child components */
  children: PropTypes.node,
  /** Extra classes */
  className: PropTypes.string,
  /** Title to display */
  title: PropTypes.node,
  /** Used to define child nodes to perform an action, ie: Button. Shows up just below the title. */
  action: PropTypes.node
};

MainBodySection.defaultProps = {
  className: ''
};

MainBodySection.displayName = 'Page.MainBodySection';

export default MainBodySection;

export const {
  propTypes,
  defaultProps,
  displayName
} = MainBodySection;
