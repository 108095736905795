import React from 'react';

const Footer = ({ children }) => (
  children ? (
    <footer className="particles-modal__footer">{children}</footer>
  ) : null
);

Footer.displayName = 'Modal.Footer';

export default Footer;

export const { displayName } = Footer;