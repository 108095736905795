import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TableOverlay = ({ className, status, ...props }) => {
  const classes = classnames('particles-table__overlay', className, {
    [`particles-table__${status}`]: status
  });
  return <div className={classes} {...props} />;
};

TableOverlay.propTypes = {
  /** Adds status className*/
  status: PropTypes.string
};

TableOverlay.defaultProps = {};

TableOverlay.displayName = 'Table.Overlay';

export default TableOverlay;

export const { propTypes, defaultProps, displayName } = TableOverlay;
