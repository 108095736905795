import React from 'react';
import classnames from 'classnames';

const TableHeader = ({ className, header, ...props }) => {
  const classes = classnames('particles-table__table-header', className);
  return <thead className={classes} {...props} />;
};

TableHeader.propTypes = {};

TableHeader.defaultProps = {};

TableHeader.displayName = 'Table.Header';

export default TableHeader;

export const { propTypes, defaultProps, displayName } = TableHeader;
