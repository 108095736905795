import React from 'react';
import classnames from 'classnames';

const TableBody = ({ className, ...props }) => {
  const classes = classnames('particles-table__table-body', className);
  return <tbody className={classes} {...props} />;
};

TableBody.propTypes = {};

TableBody.defaultProps = {};

TableBody.displayName = 'Table.Body';

export default TableBody;

export const { propTypes, defaultProps, displayName } = TableBody;
