import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useMaturityLevel } from '../../component-maturity.js';

/** A Collection is used to provide proper spacing between and surrounding a set of components. */
const Collection = (props) => {
  useMaturityLevel('stable', 'Collection');
  const {
    as: ElementType,
    children,
    className,
    right,
    vertical,
    wrap,
    compact,
    ...rest
  } = props;
  const classes = classnames(
    'particles-collection',
    {
      'particles-collection--compact': !!compact,
      'particles-collection--wrap': !!wrap,
      'particles-collection--left': !right && !vertical,
      'particles-collection--right': !!right && !vertical,
      'particles-collection--vertical-left': !!vertical,
      'particles-collection--vertical-right': !!vertical && !!right
    },
    className
  );
  return (
    <ElementType className={classes} {...rest}>{children}</ElementType>
  );
};

Collection.propTypes = {
  /** Render as this kind of component. ie: [a, Link, Button, ...] */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Style with less spacing*/
  compact: PropTypes.bool,
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string,
  /** Right justify elements */
  right: PropTypes.bool,
  /** Layout elements vertically */
  vertical: PropTypes.bool,
  /** Allow wrapping elements over multiple lines */
  wrap: PropTypes.bool
};

Collection.defaultProps = {
  as: 'div',
  className: '',
  right: false,
  vertical: false,
  wrap: false
};

Collection.displayName = 'Collection';

export default Collection;

export const {
  propTypes,
  defaultProps,
  displayName
} = Collection;
