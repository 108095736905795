import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Table from '../../Table/index.js';

const DatagridHeader = ({
  headers,
  orderedBy,
  onOrderedByChange,
  itemsPerPage,
  compact
}) => (
  <Table.Header>
    <Table.Row>
      {_.map(headers, (header, name) => {
        const orderedByThisColumn = name === _.get(orderedBy, 'name');
        const desc = !!_.get(orderedBy, 'desc');
        const sortIndicator = orderedByThisColumn ? (
          <i className={`fa fa-long-arrow-${desc ? 'up' : 'down'}`} />
        ) : null;
        const cellProps = {
          compact,
          cellName: name,
          sortable: !!itemsPerPage,
          sorted: !!orderedByThisColumn,
          header: true,
          onClick: () =>
            onOrderedByChange({
              name,
              desc: orderedByThisColumn && !desc
            })
        };
        return (
          <Table.Cell key={name} {...cellProps}>
            {header}
            {sortIndicator && ' '}
            {sortIndicator}
          </Table.Cell>
        );
      })}
    </Table.Row>
  </Table.Header>
);

DatagridHeader.propTypes = {
  headers: PropTypes.object,
  orderedBy: PropTypes.object,
  onOrderedByChange: PropTypes.func,
  itemsPerPage: PropTypes.oneOf([0, 5, 10, 20, 50]),
  compact: PropTypes.bool
};

DatagridHeader.defaultProps = {
  headers: {},
  orderedBy: undefined,
  onOrderedByChange: _.noop,
  itemsPerPage: 0,
  compact: false
};

DatagridHeader.displayName = 'DatagridHeader';

export default DatagridHeader;

export const { propTypes, defaultProps, displayName } = DatagridHeader;
