import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useMaturityLevel } from '../../component-maturity.js';
import { useT } from '../../translations/index.js';

const classes = (className, { container, itemsShown, page, pageSelector, currentPage, perPage, itemsPerPageSelector, itemsPerPage, currentItemsPerPage }) => _.uniq(_.compact([
  className,
  container && 'particles-pagination-controls__container',
  itemsShown && 'particles-pagination-controls__items-shown',
  pageSelector && 'particles-pagination-controls__page-selector',
  currentPage && 'particles-pagination-controls__page--current-page',
  page && 'particles-pagination-controls__page',
  perPage && 'particles-pagination-controls__per-page',
  itemsPerPageSelector && 'particles-pagination-controls__items-per-page-selector',
  itemsPerPage && 'particles-pagination-controls__items-per-page',
  currentItemsPerPage && 'particles-pagination-controls__items-per-page--current-items-per-page'
])).join(' ');

  const ItemsShown = ({ className, totalNumberOfItems, pageStart, pageEnd }) => {
    useMaturityLevel('feedback', 'PaginationControls.ItemsShown');
    const t = useT();
    return <span className={classes(className, { itemsShown: true })}>
      {t('Showing {from, number}-{to, number} of {total, number}', { from: +(pageStart ? pageStart : 0), to: +(pageEnd ? pageEnd : 0), total: +(totalNumberOfItems ? totalNumberOfItems : 0) })}
    </span>;
  };

  const PageSelector = ({ className, totalNumberOfPages, displayedPageNumbers, currentPage, onCurrentPageChange }) => {
    useMaturityLevel('feedback', 'PaginationControls.PageSelector');
    const handlePageChange = _.isFunction(onCurrentPageChange) ? onCurrentPageChange : () => {};
    return <span className={classes(className, { pageSelector: true })}>
      <button className={classes(className, { page: true })} onClick={() => handlePageChange(1)} disabled={+currentPage === 1 ? 'disabled': ''}>
        <i className="fa fa-angle-double-left" />
      </button>
      <button className={classes(className, { page: true })} onClick={() => handlePageChange(currentPage - 1)} disabled={+currentPage === 1 ? 'disabled': ''}>
        <i className="fa fa-angle-left" />
      </button>

      {_.isArray(displayedPageNumbers) ? displayedPageNumbers.map(page => {
        return <button key={page} className={classes(className, { page: true, currentPage: +page === +currentPage})} onClick={(event) => handlePageChange(+page)}>{page}</button>;
      }) : null}

      <button className={classes(className, { page: true })} onClick={() => handlePageChange(currentPage + 1)} disabled={+currentPage === +totalNumberOfPages ? 'disabled' : ''}>
        <i className="fa fa-angle-right" />
      </button>
      <button className={classes(className, { page: true })} onClick={() => handlePageChange(totalNumberOfPages)} disabled={+currentPage === +totalNumberOfPages ? 'disabled' : ''}>
        <i className="fa fa-angle-double-right" />
      </button>
    </span>;
  };

  const ItemsPerPage = ({ className, itemsPerPageOptions, itemsPerPage, onItemsPerPageChange }) => {
    useMaturityLevel('feedback', 'PaginationControls.ItemsPerPage');
    const t = useT();
    const handleOnItemsPerPageChange = _.isFunction(onItemsPerPageChange) ? onItemsPerPageChange : () => {};
    return <span className={classes(className, { perPage: true })}>
      {t('Rows')}
      <span className={classes(className, { itemsPerPageSelector: true })}>
        {_.isArray(itemsPerPageOptions) ? itemsPerPageOptions.map(items => {
          return (
            <button key={items} className={classes(className, { itemsPerPage: true, currentItemsPerPage: +items === +itemsPerPage })} onClick={() => handleOnItemsPerPageChange(items)}>{items}</button>
          );
        }) : null}
      </span>
    </span>;
  };

const PaginationControls = ({ className, children }) => {
  useMaturityLevel('feedback', 'PaginationControls');
  return <div className={classes(className, { container: true })}>
    {children}
  </div>;
};

PaginationControls.propTypes = {
  /** Child components */
  children: PropTypes.node,
  /** total number of items, e.g. table rows */
  totalNumberOfItems: PropTypes.number,
  /** index of first item on current page */
  pageStart: PropTypes.number,
  /** index of last item on current page */
  pageEnd: PropTypes.number,
  /** Total number of pages */
  totalNumberOfPages: PropTypes.number,
  /** page numbers to display on controls */
  displayedPageNumbers: PropTypes.array,
  /** current page number */
  currentPage: PropTypes.number,
  /** function that handles a page change */
  onCurrentPageChange: PropTypes.func,
  /** array of numbers indicating page size options */
  itemsPerPageOptions: PropTypes.array,
  /** number of items per page - the default value from itemsPerPageOptions */
  itemsPerPage: PropTypes.number,
  /** function that handles a change in itemsPerPage */
  onItemsPerPageChange: PropTypes.func
};

PaginationControls.defaultProps = {};

PaginationControls.displayName = 'PaginationControls';

PaginationControls.ItemsShown = ItemsShown;

PaginationControls.PageSelector = PageSelector;

PaginationControls.ItemsPerPage = ItemsPerPage;

export default PaginationControls;

export const { propTypes, defaultProps, displayName } = PaginationControls;
