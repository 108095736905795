import React from 'react';

const Body = ({ children } = {}) => (
  children ? (
    <div className="particles-modal__body">{children}</div>
  ) : null
);

Body.displayName = 'Modal.Body';

export default Body;

export const {
  displayName
} = Body;