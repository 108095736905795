import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from './PageContainer/index.js';
import Main from './PageMain/index.js';
import MainHeader from './PageMainHeader/index.js';
import MainBody from './PageMainBody/index.js';
import Banner from './PageBanner/index.js';
import MainBodySection from './PageMainBodySection/index.js';
import { useMaturityLevel } from '../../component-maturity.js';

const Page = ({ className, layout, ...rest }) => {
  useMaturityLevel('stable', 'Page');
  const classes = classnames('particles-page', { 'particles-page--layout': layout }, className);
  return (
    <div className={classes} {...rest} />
  );
};

Page.propTypes = {
  /** Child components */
  children: PropTypes.node,
  /** Extra classes */
  className: PropTypes.string
};

Page.defaultProps = {
  className: ''
};

Page.displayName = 'Page';


Page.Container = Container;

Page.Main = Main;
Page.MainHeader = MainHeader;
Page.MainBody = MainBody;
Page.MainBodySection = MainBodySection;
Page.Banner = Banner;

export default Page;
export { Container, Main, MainHeader, MainBody, MainBodySection, Banner };

export const {
  propTypes,
  defaultProps,
  displayName
} = Page;
