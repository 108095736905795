import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import bem from '../../utils/bem.js';
import PropTypes from 'prop-types';
import { useMaturityLevel } from '../../component-maturity.js';

const block = 'button-group';

const ButtonGroup = (props) => {
  useMaturityLevel('feedback', 'ButtonGroup');
  const { children, className } = props;
  const classes = classnames(bem(block), className);
  return (
    <div className={classes}>
      {React.Children.map(children, (child, isValue) => (
        (child && React.isValidElement(child)) ? React.cloneElement(child, {
          className: classnames(bem(block, 'button'), _.get(child, 'props.className'))
        }) : child
      ))}
    </div>
  );
};

ButtonGroup.displayName = 'ButtonGroup';

ButtonGroup.propTypes = {
  /** extra class name for the button group */
  className: PropTypes.string
};

ButtonGroup.defaultProps = {
  className: ''
};

export default ButtonGroup;
