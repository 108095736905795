import _ from 'lodash';
import { useConfiguration } from './configuration.js';
import { useParticlesConsole } from './console.js';

const notificationDelivery = (particlesConsole, notificationLevel) => (notification) => _.get({
  fatal: (message) => {
    const error = new Error(message());
    error.name = '@janus.team/janus-particles/UnsafeComponentMaturityLevelError';
    throw error;
  },
  log: (message) => particlesConsole.info(message()),
  info: (message) => particlesConsole.info(message()),
  warn: (message) => particlesConsole.warn(message()),
  warning: (message) => particlesConsole.warn(message()),
  err: (message) => particlesConsole.error(message()),
  error: (message) => particlesConsole.error(message())
}, notificationLevel, _.noop)(notification);

const notify = (notificationLevel, level, component, particlesConsole) => {
  const name = _.get(component, 'name', component);
  notificationDelivery(particlesConsole, notificationLevel)(() => `

  ========================================================================
  #  @janus.team/janus.particles - component maturity level notification
  #    => component: [${name}]
  #    => maturity level: [${level}]
  ========================================================================

  => best effort at determining where this originated from in your app: ${(new Error()).stack}

  ------------------------------------------------------------------------

  Component maturity level is defined as:

  - deprecated: component will soon no longer be supported
  - pre: component is not released, use at your own risk, it might be completely changed and/or removed without warning in future releases
  - initial: no polish, rough initial cut, might not match helix correctly, might require some heavy consumer work to consume properly
  - unstable: low levels of polish, component partially matches helix but some details/cases might be missing, component API might still be subject to change
  - feedback: component close to fully matches helix and API should be close to final, changes from this point ownwards are expected mostly due to feedback from consumers
  - stable: we consider the component very close to done, it is consumed in production by at least one application and API changes are unlikely, maybe some styling fixes here and there
  - proven: component has been in use for a while in more than one application, API is pretty much set in stone, only helix compliance styling changes are expected

  We highly encourage you to use components as early in their maturity level as possible (if you can afford dealing with some level of churn).
  By jumping aboard early you get to help shape the component and make sure it meets the use case of your application.

  For the sake of expectation management we came up with this system, and we setup sensible default notifications:

  - using a component in [pre] maturity level will make your application crash
  - using a component in [deprecated] maturity level will generate error level console notifications
  - using a component in [initial] maturity level will generate error level console notifications
  - using a component in [unstable] maturity level will generate warning level console notifications
  - using a component in [feedback] maturity level will generate info level console notifications
  - using a component in [stable] or [proven] maturity level will generate no notifications

  If you wish to change these levels (we imagine most people might want to disable info notifications for feedback level components),
  you can do so like this:

  import { configuration } from '@janus.team/janus.particles';

  // valid 'level' keys are: 'pre', 'initial', 'unstable', 'feedback', 'stable', 'proven'
  // valid 'level' values are: 'fatal', 'error', 'warning', 'info', 'none'
  <configuration.ConfigurationProvider componentMaturity={{
    level: {
      deprecated: 'fatal',
      pre: 'fatal',
      initial: 'error',
      unstable: 'warning',
      feedback: 'info',
      stable: 'none',
      proven: 'none'
    }
  }}>
    <YourAppHere />
  </configuration.ConfigurationProvider>

  // alternatively you can also disable on a component by component basis (component specific settings take priority over level seetings)
  // valid 'component' keys are component names
  // valid 'component' values are: 'fatal', 'error', 'warning', 'info', 'none'
  <configuration.ConfigurationProvider componentMaturity={{
    component: {
      PreReleasedComponentThatWeWantToUseAnyway: 'none'
    }
  }}>
    <YourAppHere />
  </configuration.ConfigurationProvider>
  `);
};

const useMaturityLevel = (level, component) => {
  const configuration = useConfiguration() || {};
  const particlesConsole = useParticlesConsole() || _.noop;
  const notificationLevel =
    _.get(configuration.componentMaturity, ['component', component]) ||
    _.get(configuration.componentMaturity, ['level', level]) ||
    'none';
  notify(notificationLevel, level, component, particlesConsole);
};

const MaturityLevel = ({ level, component }) => {
  useMaturityLevel(level, component);
  return null;
};

export default { useMaturityLevel, MaturityLevel };
export { useMaturityLevel, MaturityLevel };
