import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Header = (props) => {
  const { children, className, as: Component, ...rest } = props;
  const headerClasses = classnames(
    'particles-card__header',
    className
  );
  return (
    <Component className={headerClasses} {...rest}>
      {children}
    </Component>
  );
};

Header.propTypes = {
  /** Render as this kind of component. ie: [a, Link, Button, ...] */
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.elementType]),
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string
};
Header.defaultProps = {
  as: 'div'
};
Header.displayName = 'Card.Header';

export default Header;

export const {
  propTypes,
  defaultProps,
  displayName
} = Header;
