import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Pagination from './Pagination/index.js';
import Table from '../Table/index.js';
import DatagridHeader from './DatagridHeader/index.js';
import DatagridBody from './DatagridBody/index.js';
import { useMaturityLevel } from '../../component-maturity.js';

const getPaginationData = ({
  itemsPerPage,
  data,
  currentPage,
  pageSizes,
  compact,
  onItemsPerPageChange,
  onCurrentPageChange
}) => {
  const numberOfPages = itemsPerPage ? Math.ceil(data.length / +itemsPerPage) : 1;
  const adjustedCurrentPage = numberOfPages ? Math.min(+currentPage, numberOfPages - 1) : 0;
  const pageStart = adjustedCurrentPage * +itemsPerPage;
  const pageEnd = Math.min(data.length, pageStart + +itemsPerPage);
  const pageData = itemsPerPage ? _.slice(data, pageStart, pageEnd) : data;
  const paginationData = {
    pageSizes,
    compact,
    numberOfPages,
    numberOfItemsTotal: data.length,
    pageStart,
    pageEnd,
    itemsPerPage,
    currentPage: adjustedCurrentPage,
    onCurrentPageChange,
    onItemsPerPageChange
  };
  return { paginationData, pageData };
};

const renderTable = (props) => {
  const {
    paginationData,
    pageData,
    compact,
    headers,
    className
  } = props;

  return [
    <Pagination key="pagination" {...paginationData} />,
    <Table key="table" className={className} compact={compact}>
      {_.some(headers) && <DatagridHeader {...props} />}
      {_.some(pageData) && <DatagridBody pageData={pageData} {...props} />}
    </Table>
  ];
};

const shouldShowOverlay = ({ loading, error, empty }) => loading || error || empty;

const Overlay = ({ whenLoading, whenError, whenEmpty, loading, error, empty }) => {
  if (loading) {
    return <Table.Overlay status="loading">{whenLoading}</Table.Overlay>;
  } else if (error) {
    return <Table.Overlay status="error">{whenError}</Table.Overlay>;
  } else if (empty) {
    return <Table.Overlay status="empty">{whenEmpty}</Table.Overlay>;
  }
  return null;
};

const Datagrid = (props) => {
  useMaturityLevel('feedback', 'Datagrid');
  const {
    compact,
    className,
    loading,
    error,
    showTableWhenEmpty,
    showTableWhenLoading,
    showTableWhenError,
    whenEmpty,
    whenLoading,
    whenError
  } = props;

  const { paginationData, pageData } = getPaginationData(props);
  const empty = _.isEmpty(pageData);

  const hideBaseTable = (loading && !showTableWhenLoading) || (error && !showTableWhenError) || (empty && !showTableWhenEmpty);

  const tableProps = shouldShowOverlay({ loading, error, empty }) ? { paginationData, ...props } : { paginationData, pageData, ...props };

  return (
    <Table.Wrapper className={className} compact={compact}>
      {hideBaseTable ? null : renderTable(tableProps)}
      <Overlay {...{ loading, error, whenEmpty, whenLoading, whenError, empty }} />
    </Table.Wrapper>
  );
};

Datagrid.propTypes = {
  /** Reducing spacing to make table smaller */
  compact: PropTypes.bool,
  /** Headers to show for each column */
  headers: PropTypes.object,
  /** Number of records to show per page */
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  /** Data to display in the table */
  data: PropTypes.arrayOf(PropTypes.object),
  /** Field to order table items by  */
  orderedBy: PropTypes.object,
  /** Number of records to show per page */
  itemsPerPage: PropTypes.oneOf([0, 5, 10, 20, 50]),
  /** Current page to display when using pagination */
  currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Event listener for when `currentPage` property changes */
  onCurrentPageChange: PropTypes.func,
  /** Event listener for when `itemsPerPage` property change */
  onItemsPerPageChange: PropTypes.func,
  /** Event listener for when `orderedBy` property change */
  onOrderedByChange: PropTypes.func,
  /** Set loading property */
  loading: PropTypes.bool,
  /** Set error property */
  error: PropTypes.bool,
  /** Whether to show table when there is no data */
  showTableWhenEmpty: PropTypes.bool,
  /** Whether to show table when loading state is true */
  showTableWhenLoading: PropTypes.bool,
  /** Whether to show table when error state is true */
  showTableWhenError: PropTypes.bool,
  /** The element to show when the table is empty and has no data */
  whenEmpty: PropTypes.node,
  /** The element to show when the `loading` property is true */
  whenLoading: PropTypes.node,
  /** The element to show when the `error` property is true */
  whenError: PropTypes.node
};

Datagrid.defaultProps = {
  pageSizes: [5, 10, 20, 50],
  compact: false,
  data: [],
  headers: {},
  itemsPerPage: 0,
  currentPage: 0,
  orderedBy: undefined,
  onCurrentPageChange: _.noop,
  onItemsPerPageChange: _.noop,
  onOrderedByChange: _.noop,
  loading: false,
  error: false,
  showTableWhenEmpty: false,
  showTableWhenLoading: false,
  showTableWhenError: false,
  whenEmpty: 'Empty...',
  whenLoading: 'Loading...',
  whenError: 'Error'
};

Datagrid.displayName = 'Datagrid';

export default Datagrid;

export const {
  propTypes,
  defaultProps,
  displayName
} = Datagrid;
