import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Error = (props = {}) => {
  const { className, type, name, children } = props;
  const errorClasses = classnames(
    'particles-input__error',
    `particles-input__error--${type}`,
    `particles-input__error--${name}`,
    className
  );
  return (
    <div className={errorClasses}>
      <i className="fa fa-exclamation-circle" />{' '}
      {children}
    </div>
  );
};

Error.displayName = 'Error';
Error.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** Child components */
  children: PropTypes.node,
  /** Type of input the error occured on */
  type: PropTypes.string,
  /** `name` attribute value */
  name: PropTypes.string
};

export default Error;

export const {
  displayName
} = Error;
