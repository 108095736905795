import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Bar from '../Bar/index.js';
import { useMaturityLevel } from '../../../component-maturity.js';
import { NavigationTypeContext, useNavigationType } from '../context.js';

const Primary = (props) => {
  useMaturityLevel('stable', 'Navigation.Primary');
  const { className, as: Component, ...rest } = props;
  const navigationType = useNavigationType({ ...props, primary: true });
  const primaryClasses = classnames(
    'particles-primary-navigation',
    `particles-navigation-${navigationType}`,
    className
  );
  const containerClasses = classnames('particles-primary-navigation__container');
  return (
    <NavigationTypeContext {...props} primary>
      <Component className={primaryClasses} containerClassName={containerClasses} {...rest} />
    </NavigationTypeContext>
  );
};

Primary.defaultProps = {
  as: Bar
};
Primary.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div', 'section', 'nav' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Primary.displayName = 'Navigation.Primary';

export default Primary;

export const {
  defaultProps,
  propTypes,
  displayName
} = Primary;
