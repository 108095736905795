import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Page from '../../Page/index.js';
import { NavigationTypeContext, useNavigationType } from '../context.js';
import { useMaturityLevel } from '../../../component-maturity.js';
import _ from 'lodash';

const Bar = (props) => {
  useMaturityLevel('stable', 'Navigation.Bar');
  const { className, children, noContainer, containerClassName, as: Component, ...rest } = props;
  const navigationType = useNavigationType(props);
  const barClasses = classnames('particles-navigation-bar', `particles-navigation-bar--${navigationType}`, className);
  const containerClasses = classnames('particles-navigation-bar__container', `particles-navigation-bar__container--${navigationType}`, className);
  return (
    <NavigationTypeContext {...props}>
      <Component className={barClasses} {..._.omit(rest, ['primary', 'footer', 'context', 'utility'])}>
        {noContainer ? children : (
          <Page.Container className={containerClasses}>
            {children}
          </Page.Container>
        )}
      </Component>
    </NavigationTypeContext>
  );
};

Bar.defaultProps = {
  as: 'nav'
};
Bar.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div', 'section', 'nav' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Bar.displayName = 'Navigation.Bar';

export default Bar;

export const {
  defaultProps,
  propTypes,
  displayName
} = Bar;
