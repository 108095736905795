import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import Label from '../Input/Label/index.js';
import Error from '../Input/Error/index.js';
import Help from '../Input/Help/index.js';
import propGroups from '../Input/props.js';
import PropTypes from 'prop-types';

const Group = (props = {}) => {
  const { name, type, className, children, disabled, required, inline } = props;
  const groupClasses = classnames(
    'particles-form__group',
    `particles-form__group--${name}`,
    className,
    {
      'particles-form__group--required': !!required,
      'particles-form__group--disabled': !!disabled
    }
  );
  const label = (
    <Label
      {...propGroups.common(props)}
      {...propGroups.meta(props)}
      type="group"
      required={!!required}
    >{props.label}</Label>
  );
  const error = (props.touched || props.dirty) && props.error && (
    <Error type={type} name={name}>{props.error}</Error>
  );
  const help = props.help && (
    <Help type={type} name={name}>{props.help}</Help>
  );
  const groupMembersClasses = classnames(
    'particles-form__group-members',
    {
      'particles-form__group-members--inline': !!inline
    }
  );
  return (
    <div className={groupClasses}>
      <div className="particles-form__group-label">
        {label}
      </div>
      <div className={groupMembersClasses}>
        {React.Children.map(children, child => (
          _.isString(child) ? child : React.cloneElement(child, {
            className: classnames(
              'particles-form__group-member',
              child.props && child.props.className,
              {
                'particles-form__group-member--inline': !!inline
              }
            )
          })
        ))}
      </div>
      {help}
      {error}
    </div>
  );
};

Group.displayName = 'Group';
Group.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** Group type */
  type: PropTypes.string,
  /** Child components */
  children: PropTypes.node,
  /** `name` attribute value to apply to elements within the grouping */
  name: PropTypes.string,
  /** Disables the group */
  disabled: PropTypes.bool,
  /** Marks elements within group as required */
  required: PropTypes.bool,
  /** Apply inline styling to the group */
  inline: PropTypes.bool
};

export default Group;

export const {
  displayName,
  propTypes
} = Group;
