import React from 'react';
import PropTypes from 'prop-types';
import Page from '../Page/index.js';
import { useMaturityLevel } from '../../component-maturity.js';

export const Link = ({ as: Component, className, ...rest } = {}) => (
  <Component {...rest} className={`particles-breadcrumbs__link ${className}`} />
);

export const Breadcrumb = ({ as: Component, className, ...rest } = {}) => (
  <Component {...rest} className={`particles-breadcrumbs__breadcrumb ${className}`} />
);

export const Current = ({ className, ...rest } = {}) => (
  <Breadcrumb {...rest} className={`particles-breadcrumbs__breadcrumb--current ${className}`} />
);

export const Parent = ({ className, ...rest } = {}) => (
  <Breadcrumb {...rest} className={`particles-breadcrumbs__breadcrumb--parent ${className}`} />
);

export const Breadcrumbs = ({ nav: Nav, as: Component, className, container: Container, ...rest } = {}) => {
  useMaturityLevel('feedback', 'Breadcrumbs');
  return (
    <Nav className={`particles-breadcrumbs__nav ${className}`}>
      <Container className="particles-breadcrumbs__container">
        <Component className="particles-breadcrumbs__breadcrumbs" {...rest} />
      </Container>
    </Nav>
  );
};

Breadcrumb.displayName = 'Breadcrumbs.Breadcrumb';
Parent.displayName = 'Breadcrumbs.Breadcrumb.Parent';
Current.displayName = 'Breadcrumbs.Breadcrumb.Current';
Link.displayName = 'Breadcrumbs.Link';

Breadcrumb.defaultProps = {
  as: 'li'
};
Link.defaultProps = {
  className: '',
  as: 'a'
};
Breadcrumbs.defaultProps = {
  className: '',
  container: Page.Container,
  as: 'ol',
  nav: 'nav'
};
Breadcrumbs.propTypes = {
  /** Uses this component as the list [ol, ul, ...] */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Uses this component as the container. ie: [div, Page.Container, ...] */
  container: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Uses this component as the nav. ie: [nav, ...] */
  nav: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Extra class names */
  className: PropTypes.string
};

Breadcrumbs.displayName = 'Breadcrumbs';
Breadcrumbs.Breadcrumb = Breadcrumb;
Breadcrumbs.Link = Link;
Breadcrumbs.Current = Current;
Breadcrumbs.Parent = Parent;
Breadcrumb.Link = Link;
Breadcrumb.Current = Current;
Breadcrumb.Parent = Parent;


export default Breadcrumbs;

export const {
  propTypes,
  defaultProps,
  displayName
} = Breadcrumbs;
