import React from 'react';
import Label from './Label/index.js';
import Help from './Help/index.js';
import Error from './Error/index.js';
import Selectable from './Selectable/index.js';
import Checkable from './Checkable/index.js';
import Text from './Text/index.js';
import TextArea from './TextArea/index.js';
import propGroups from './props.js';
import PropTypes from 'prop-types';
import _ from 'lodash';

const { isCheckable } = Checkable;
const { isSelectable } = Selectable;
const { isTextArea } = TextArea;

const generateId = props => (isCheckable(props) ?
  `form-field-${props.name}-${props.value}` :
  `form-field-${props.name}`);
const idFrom = props => (props.id || generateId(props));

const componentAndBaseProps = (props) => {
  if (isCheckable(props)) {
    return {
      Component: Checkable,
      baseProps: {
        ...propGroups.eventHandlers(props),
        ...propGroups.common(props),
        ...propGroups.checkable(props),
        ...propGroups.meta(props)
      }
    };
  }
  if (isSelectable(props)) {
    return {
      Component: Selectable,
      baseProps: {
        ...propGroups.eventHandlers(props),
        ...propGroups.common(props),
        ...propGroups.selectable(props),
        ...propGroups.meta(props)
      }
    };
  }
  if (isTextArea(props)) {
    return {
      Component: TextArea,
      baseProps: {
        ...propGroups.eventHandlers(props),
        ...propGroups.common(props),
        ...propGroups.textArea(props),
        ...propGroups.meta(props)
      }
    };
  }
  return {
    Component: Text,
    baseProps: {
      ...propGroups.eventHandlers(props),
      ...propGroups.common(props),
      ...propGroups.text(props),
      ...propGroups.meta(props)
    }
  };
};
const Input = (props = {}) => {
  const { required, name, type } = props;
  const id = idFrom(props);
  const label = props.label && (
    <Label
      htmlFor={id}
      {...propGroups.common(props)}
      {...propGroups.meta(props)}
      required={!!required}
    >{props.label}</Label>
  );
  const help = props.help && (
    <Help
      {...propGroups.common(props)}
      {...propGroups.meta(props)}
    >{props.help}</Help>
  );
  const error = props.touched && props.error && (
    <Error type={type} name={name}>{props.error}</Error>
  );
  const { Component, baseProps } = componentAndBaseProps(props);
  return (
    <Component
      {...baseProps}
      error={error}
      label={label}
      help={help}
      id={id}
      onChange={props.onChange || _.noop}
    />
  );
};

Input.Label = Label;
Input.Text = Text;
Input.Selectable = Selectable;
Input.Checkable = Checkable;
Input.Help = Help;
Input.Error = Error;
Input.displayName = 'Input';
Input.propTypes = {
  /** Marks the input as required (no effect other than visual) */
  required: PropTypes.bool,
  /** Used to create inputs with a button */
  children: PropTypes.node,
  /** Extra classes */
  className: PropTypes.string,
  /** HTML id, defaults to same as name */
  id: PropTypes.string,
  /** HTML name, should be included in every input */
  name: PropTypes.string,
  /** Type: [text, hidden, password, email, textarea, checkbox, radio, select] */
  type: PropTypes.string,
  /** Label describing the input */
  label: PropTypes.node,
  /** Error associated with the input (only shown if marked as touched) */
  error: PropTypes.node,
  /** Extra bit of text to help users fill the input */
  help: PropTypes.node,
  /** Pattern used when HTML 5 validation is turned on */
  pattern: PropTypes.string,
  /** Determines if an input has been touched and if errors should be shown */
  touched: PropTypes.bool
};

export default Input;
export { Label, Text, Selectable, Checkable, Help, Error, TextArea };

export const {
  displayName,
  propTypes
} = Input;
