import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import context from './context.js';

const dropdownItemClasses = () => _.compact(['particles-dropdown__item']).join(' ');
const DropdownItem = ({ children, as: Component, onClick, ...rest }) => {
  const { getItemProps } = React.useContext(context.dropdownState);
  return <Component {...getItemProps({ onClick, item: children, children, className: dropdownItemClasses()})} {...rest} />;
};
DropdownItem.displayName = 'Dropdown.Item';
DropdownItem.defaultProps = { as: 'span' };
DropdownItem.propTypes = {
  /** Render as this kind of component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string
};

export default DropdownItem;
