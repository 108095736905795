import _ from 'lodash';

const languageFromNavigator = () => (/^(ja|jp|ja-jp)/i).test(
  global.navigator.language || _.first(global.navigator.languages)
) ? 'ja-JP' : 'en-US';

const forceLocale = () => _.get((/forceLocale=([^&]+)/i).exec(`${global.location.search}`), 1) || undefined;
const locale = () => (forceLocale() || languageFromNavigator() || 'en-US');

export default { languageFromNavigator, forceLocale, locale };
export { languageFromNavigator, forceLocale, locale };
