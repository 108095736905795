import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useMaturityLevel } from '../../component-maturity.js';

const KeyValuePair = (props) => {
  useMaturityLevel('stable', 'KeyValuePair');
  const { className, children, vertical, displayValue, size, ...rest } = props;
  const classes = classnames(
    'particles-key-value-pair',
    className,
    `particles-key-value-pair--size-${size}`,
    {
      'particles-key-value-pair--display-value': !!displayValue,
      'particles-key-value-pair--horizontal': !vertical,
      'particles-key-value-pair--vertical': !!vertical
    });
  return (
    <div className={classes} {...rest}>
      {React.Children.map(children, (child, isValue) => (
        React.cloneElement(child, {
          className: classnames(child.props.className, {
            [`particles-key-value-pair__key--size-${size}`]: !isValue,
            'particles-key-value-pair__key--display-value': !isValue && !!displayValue,
            'particles-key-value-pair__key': !isValue,
            'particles-key-value-pair__key--horizontal': !isValue && !vertical,
            'particles-key-value-pair__key--vertical': !isValue && !!vertical,
            [`particles-key-value-pair__value--size-${size}`]: !!isValue,
            'particles-key-value-pair__value--display-value': !!isValue && !!displayValue,
            'particles-key-value-pair__value': !!isValue,
            'particles-key-value-pair__value--horizontal': !!isValue && !vertical,
            'particles-key-value-pair__value--vertical': !!isValue && !!vertical
          })
        })
      ))}
    </div>
  );
};

KeyValuePair.propTypes = {
  /**
   * determines how much space will be allocated for keys in the pair,
   * only applicable for horizontal key value pairs.
   */
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large', 'huge', 'unbound']),
  /** key and set of values (first child is the key, next children are considered values) */
  children: PropTypes.node,
  /** extra classes to set */
  className: PropTypes.string,
  /** should show a larger value, meant to be displayed centered, likely in a card */
  displayValue: PropTypes.bool,
  /** vertical instead of horizontal key value pair */
  vertical: PropTypes.bool
};

KeyValuePair.defaultProps = {
  size: 'unbound',
  displayValue: false,
  vertical: false,
  className: ''
};

export default KeyValuePair;

export const {
  propTypes,
  defaultProps
} = KeyValuePair;
