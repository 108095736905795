import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input.js';

const ReduxFormInputAdapter = (props) => {
  const { input, meta, ...rest } = props;

  // eslint-disable-next-line
  console.warn(`
================================================================================================================================================
*** DEPRECATION WARNING ***

Support for redux-form has been deprecated as of janus-particles 5.0.0.

The <Form.ReduxForm> and <Input.ReduxForm> adapters will be removed in a 5.x.x release.
If you are relying on those, please consider either porting the adapter code over, or writing your own layer to communicate with redux-form.

================================================================================================================================================
`, `Please check: ${new Error().stack}`);

  return (
    <Input
      {...rest}
      {...(input || {})}
      {...(meta || {})}
    />
  );
};

// https://redux-form.com/7.1.2/docs/api/field.md/#props
const reduxFormInputPropTypes = () => ({
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onDrop: PropTypes.func,
    onDragStart: PropTypes.func,
    value: PropTypes.any
  }),
  meta: PropTypes.shape({
    active: PropTypes.bool,
    autofilled: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    form: PropTypes.string,
    initial: PropTypes.string,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
    warning: PropTypes.string
  })
});

ReduxFormInputAdapter.displayName = 'ReduxFormInputAdapter';

ReduxFormInputAdapter.propTypes = {
  ...reduxFormInputPropTypes()
};

export default ReduxFormInputAdapter;

export const {
  displayName,
  propTypes
} = ReduxFormInputAdapter;
