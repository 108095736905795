import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import context from './context.js';

const dropdownMenuClasses = ({ isOpen, left, right } = {}) => _.compact([
  'particles-dropdown-menu',
  !isOpen && 'particles-dropdown-menu--closed',
  isOpen && 'particles-dropdown-menu--open',
  (right || !left) && 'particles-dropdown-menu--right',
  (!right && left) && 'particles-dropdown-menu--left'
]).join(' ');
const dropdownMenuWrapperClasses = ({ isOpen, left, right } = {}) => _.compact([
  'particles-dropdown-menu-wrapper',
  !isOpen && 'particles-dropdown-menu-wrapper--closed',
  isOpen && 'particles-dropdown-menu-wrapper--open'
]).join(' ');
const DropdownMenu = ({ children, as: Component, wrapper: WrapperComponent, left, right, ...rest }) => {
  const { isOpen, getMenuProps } = React.useContext(context.dropdownState);
  if (!isOpen) {
    return null;
  }
  return (
    <WrapperComponent {...getMenuProps({})} className={dropdownMenuWrapperClasses({ isOpen, left, right })}>
      <Component {...rest} className={dropdownMenuClasses({ isOpen, left, right })}>{children}</Component>
    </WrapperComponent>
  );
};
DropdownMenu.displayName = 'Dropdown.Menu';
DropdownMenu.defaultProps = { as: 'span', wrapper: 'span' };
DropdownMenu.propTypes = {
  /** Render as this kind of component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string
};

export default DropdownMenu;
