import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

const isSelectable = props => ([
  'select'
].indexOf(props.type) >= 0);

const whitelist = [
  'style',
  'autoFocus',
  'required',
  'name',
  'disabled',
  'readOnly',
  'value',
  'id',
  'onClick',
  'onBlur',
  'onFocus',
  'onChange'
];

const Selectable = (props = {}) => {
  const { className, children, label, name, help, placeholder, defaultValue } = props;
  const inputClasses = classnames(
    'particles-input__input',
    'particles-input__input--meta-selectable',
    'particles-input__input--select',
    `particles-input__input--${name}`,
    {
      'particles-input__input--error': !!props.error
    }
  );
  const wrapperClasses = classnames(
    'particles-input__wrapper',
    'particles-input__wrapper--meta-selectable',
    'particles-input__wrapper--select',
    `particles-input__wrapper--${name}`,
    className
  );
  const placeholderProps = {};
  const shouldHaveDefaultValue = defaultValue || placeholder;

  if (placeholder) {
    placeholderProps.required = true;
  }
  if (shouldHaveDefaultValue) {
    placeholderProps.defaultValue = defaultValue || '';
  }

  return (
    <div className={wrapperClasses}>
      {label}
      <select
        {..._.pick(props, whitelist)}
        {...placeholderProps}
        className={inputClasses}
      >
        {(placeholder) && (
          <option value={''} disabled>{placeholder}</option>
        )}
        {children}
      </select>
      {help}
      {props.error}
    </div>
  );
};

Selectable.displayName = 'Selectable';
Selectable.isSelectable = isSelectable;
Selectable.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** Child components */
  children: PropTypes.node,
  /** `name` attribute value */
  name: PropTypes.string,
  /** Label to display */
  label: PropTypes.node,
  /** Help message to display */
  help: PropTypes.node,
  /** Error message to display when there is an error */
  error: PropTypes.node,
  value: PropTypes.string,
  /** Placeholder text to display when nothing is selected. */
  placeholder: PropTypes.string
};

export default Selectable;
export { isSelectable };

export const {
  displayName,
  propTypes
} = Selectable;
