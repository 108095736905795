import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Main = ({ className, ...rest }) => {
  const classes = classnames('particles-page__main', className);
  return (
    <main className={classes} {...rest} />
  );
};

Main.propTypes = {
  /** Child components */
  children: PropTypes.node,
  /** Extra classes */
  className: PropTypes.string
};

Main.defaultProps = {
  className: ''
};

Main.displayName = 'Page.Main';

export default Main;

export const {
  propTypes,
  defaultProps,
  displayName
} = Main;
