import React from 'react';
import PropTypes from 'prop-types';
import Container from '../Page/PageContainer/index.js';
import _ from 'lodash';
import { useMaturityLevel } from '../../component-maturity.js';
import Link from '../Link/index.js';

const AlertBar = (props) => {
  useMaturityLevel('feedback', 'AlertBar');
  const { title, level, className, children, noIcon, action, dismissible, dismissed, onDismiss, ...attrs } = props;
  const baseClasses = (className) => _.compact([
    'particles-alertbar',
    className,
    `particles-alertbar--${level}`
  ]).join(' ');
  const iconClasses = (noIcon, level) => _.compact([
    'particles-alertbar__content',
    `particles-alertbar__content--${level}`,
    !noIcon && 'particles-alertbar__content--with-icon',
    !noIcon && `particles-alertbar__content--${level}-with-icon`
  ]).join(' ');
  return dismissed ? null : (
    <div {...attrs} className={baseClasses(className)}>
      <Container className='particles-alertbar__container'>
        <i className={iconClasses(noIcon, level)}></i>
        <p className='particles-alertbar__text'>
          {title && (<span className="particles-alertbar__title">{title}: </span>)}{children}
        </p>
        {action && (
          <div className="particles-alertbar__action">
            {action}
          </div>
        )}
        {dismissible && (
          <div className='particles-alertbar__dismiss'>
            <Link as='button' type='button' onClick={onDismiss}>
              ✕
            </Link>
          </div>
        )}
      </Container>
    </div>
  );
};

AlertBar.propTypes = {
  noIcon: PropTypes.bool,
  level: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  action: PropTypes.node,
  dismissible: PropTypes.bool,
  dismissed: PropTypes.bool,
  onDismiss: PropTypes.func
};

AlertBar.defaultProps = {
  noIcon: false,
  level: 'info'
};

AlertBar.displayName = 'AlertBar';

export default AlertBar;

export const {
  propTypes,
  defaultProps,
  displayName
} = AlertBar;
