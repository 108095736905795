import _ from 'lodash';
import moment from 'moment-timezone';

export const fromIntl = () => _.thru(global.Intl, intl => (
  intl ? intl.DateTimeFormat().resolvedOptions().timeZone : null
));

const usingIntl = (...args) => (moment.utc(...args).tz(fromIntl()));

const usingLocal = (...args) => (moment.utc(...args).local());

export const timezoneAwareMoment = (...args) => (usingIntl(...args) || usingLocal(...args));

export const timezoneOffset = (input) => timezoneAwareMoment(input).format('Z');
export const timezoneAbbreviation = (input) => timezoneAwareMoment(input).format('z');

const formatPreset = (presetName, customFormats) => {
  const presetFormats = _.mapValues(customFormats.date, (value, key) => key);
  return (_.get(_.merge({
    'date-time': 'particlesDateTime',
    'date-time-short': 'particlesDateTimeShort',
    'date': 'particlesDate',
    'date-short': 'particlesDateShort',
    'time': 'particlesTime'
  }, presetFormats), presetName) || 'particlesDateTime');
};

export const format = (input, presetName, t) => (
  moment.utc(input).isValid() ? t(formatPreset(presetName, t.customFormats), { date: moment.utc(input).toDate() }) : input
);

const deltaInMinutes = (t, minutes) => t.relativeTimeNumericLong(minutes, 'minute');
const deltaInSeconds = (t, seconds) => t.relativeTimeNumericLong(seconds, 'second');
const deltaInHours = (t, hours) => t.relativeTimeNumericLong(hours, 'hour');
const deltaInDays = (t, days) => t.relativeTimeNumericLong(days, 'day');

const resolution = (t) => ([
  [input => (Math.abs(input) >= (24 * 3600)), [
    [input => Math.trunc(input / (24 * 3600)), _.partial(deltaInDays, t)]
  ]],
  [input => (Math.abs(input) >= (12 * 3600)), [
    [input => Math.trunc(input / 3600), _.partial(deltaInHours, t)]
  ]],
  [input => !(Math.abs(input) % 3600), [
    [input => Math.trunc(input / 3600), _.partial(deltaInHours, t)]
  ]],
  [input => (Math.abs(input) >= 60), [[input => Math.trunc(input / 60), _.partial(deltaInMinutes, t)]]],
  [input => (Math.abs(input) < 60), [[input => Math.trunc(input % 60), _.partial(deltaInSeconds, t)]]]
]);

export const delta = (seconds, t) => (
  _.thru(
    _.find(resolution(t), ([test]) => test(Math.abs(seconds))) || [_.identity, [_.identity, deltaInSeconds]],
    ([__, unitValueAndPresentation]) => _.reduce(
      unitValueAndPresentation,
      (result, [unitValue, presentation]) => (
        _.trim(`${result} ${unitValue(Math.abs(seconds)) ? presentation(unitValue(+seconds)) : ''}`)
      ),
      ''
    )
  )
);
export const countdownIfRecent = (seconds, input, { t, presetName, maxRelativePastDeltaInSeconds } = {}) => (
  (seconds < -(maxRelativePastDeltaInSeconds || 0)) ? format(input, presetName, t) : (
    `${delta(seconds, t)}`
  )
);

export const fromNow = (input, { t, presetName, now, maxRelativePastDeltaInSeconds }) => (
  _.thru(timezoneAwareMoment(input).diff(timezoneAwareMoment(now), 'seconds'), seconds => (
    (seconds === 0) ? 'now' : countdownIfRecent(seconds, input, {
      t, presetName, maxRelativePastDeltaInSeconds
    })
  ))
);

export default {
  fromNow,
  countdownIfRecent,
  fromIntl,
  timezoneAwareMoment,
  timezoneOffset,
  timezoneAbbreviation,
  delta,
  format
};

