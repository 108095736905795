/* eslint-disable no-console */
import { useConfiguration } from './configuration.js';
import _ from 'lodash';

const log = (...args) => console.log(...args);
const info = (...args) => console.info(...args);
const warn = (...args) => console.warn(...args);
const error = (...args) => console.error(...args);
const debug = (...args) => console.debug(...args);
const trace = (...args) => console.trace(...args);
const dir = (...args) => console.dir(...args);

const particlesConsole = (levels = {}) => _.reduce({
  log, info, warn, error, debug, trace, dir
}, (result, fn, level) => _.set(result, level, levels[level] ? fn : _.noop), {});

const useParticlesConsole = () => {
  const configuration = useConfiguration() || {};
  const levels = configuration.console || {};
  return particlesConsole(levels);
};

export { log, info, warn, error, debug, trace, dir, useParticlesConsole, particlesConsole };
export default { log, info, warn, error, debug, trace, dir, useParticlesConsole, particlesConsole };
