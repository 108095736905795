import React from 'react';
import PropTypes from 'prop-types';

export const Body = ({ as: Component, children, className, ...rest }) => {
  return (
    <Component className={`particles-choice-tile__body ${className ? className : ''}`}>
      {children}
    </Component>
  );
};
Body.defaultProps = { as: 'div' };
Body.displayName = 'ChoiceTiles.Body';
Body.propTypes = {
  /** Render as this kind of component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string
};

export default Body;
