import React from 'react';
import PropTypes from 'prop-types';

export const Icon = ({ as: Component, children, className }) => {
  return (
    <Component className={`particles-choice-tile__icon hx-tile-icon ${className ? className : ''}`}>
      {children}
    </Component>
  );
};
Icon.defaultProps = { as: 'div' };
Icon.displayName = 'ChoiceTiles.Icon';
Icon.propTypes = {
  /** Render as this kind of component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string
};

export default Icon;

