import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ as: Component, children, className, ...rest }) => {
  return (
    <Component className={`particles-choice-tile__header ${className ? className : ''}`}>
      {children}
    </Component>
  );
};

Header.defaultProps = { as: 'header' };
Header.displayName = 'ChoiceTiles.Header';
Header.propTypes = {
  /** Render as this kind of component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string
};

export default Header;
