import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Bar from '../Bar/index.js';
import { useMaturityLevel } from '../../../component-maturity.js';
import { NavigationTypeContext, useNavigationType } from '../context.js';

const Context = (props) => {
  useMaturityLevel('stable', 'Navigation.Context');
  const { className, as: Component, ...rest } = props;
  const navigationType = useNavigationType({ ...props, context: true });
  const contextClasses = classnames(
    `particles-navigation-${navigationType}`,
    'particles-context-navigation',
    className
  );
  const containerClasses = classnames('particles-context-navigation__container');
  return (
    <NavigationTypeContext {...props} context>
      <Component className={contextClasses} containerClassName={containerClasses} {...rest} />
    </NavigationTypeContext>
  );
};

Context.defaultProps = {
  as: Bar
};
Context.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div', 'section', 'nav' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Context.displayName = 'Navigation.Context';

export default Context;

export const {
  defaultProps,
  propTypes,
  displayName
} = Context;
