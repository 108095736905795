import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

const isTextArea = props => ([
  'textarea'
].indexOf(props.type) >= 0);

const whitelist = [
  'maxLength',
  'rows',
  'cols',
  'wrap',
  'style',
  'autoFocus',
  'required',
  'placeholder',
  'name',
  'disabled',
  'readOnly',
  'defaultValue',
  'value',
  'id',
  'onClick',
  'onBlur',
  'onFocus',
  'onChange',
  'pattern'
];

const TextArea = (props = {}) => {
  const { className, type, label, name, help, error, children } = props;
  const inputClasses = classnames(
    'particles-input__input',
    'particles-input__input--meta-text',
    `particles-input__input--${type}`,
    `particles-input__input--${name}`,
    {
      'particles-input__input--with-controls': !!children,
      'particles-input__input--error': !!error
    }
  );
  const wrapperClasses = classnames(
    'particles-input__wrapper',
    'particles-input__wrapper--meta-text',
    `particles-input__wrapper--${type}`,
    `particles-input__wrapper--${name}`,
    className
  );
  const input = (
    <textarea {..._.pick(props, whitelist)} className={inputClasses} />
  );
  return (
    <div className={wrapperClasses}>
      {label}
      {input}
      {help}
      {error}
    </div>
  );
};

TextArea.displayName = 'TextArea';
TextArea.isTextArea = isTextArea;

TextArea.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** Type of element, set to 'textarea' */
  type: PropTypes.string,
  /** `name` attribute value */
  name: PropTypes.string,
  /** Label to display */
  label: PropTypes.node,
  /** Help message to display */
  help: PropTypes.node,
  /** Error message to display when there is an error */
  error: PropTypes.node,
  value: PropTypes.string
};

export default TextArea;

export const {
  displayName,
  propTypes
} = TextArea;
