import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import context from './context.js';

const dropdownGroupClasses = ({ isOpen } = {}) => _.compact([
  'particles-dropdown__group',
  !isOpen && 'particles-dropdown__group--closed',
  isOpen && 'particles-dropdown__group--open'
]).join(' ');
const DropdownGroup = ({ children, as: Component, ...rest }) => {
  const { isOpen } = React.useContext(context.dropdownState);
  if (!isOpen) {
    return null;
  }
  return (
    <Component {...rest} className={dropdownGroupClasses({ isOpen })}>
      {children}
    </Component>
  );
};
DropdownGroup.displayName = 'Dropdown.Group';
DropdownGroup.defaultProps = { as: 'span' };
DropdownGroup.propTypes = {
  /** Render as this kind of component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string
};

export default DropdownGroup;
