import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Body from './TableBody/index.js';
import Cell from './TableCell/index.js';
import Header from './TableHeader/index.js';
import Overlay from './TableOverlay/index.js';
import Row from './TableRow/index.js';
import Wrapper from './TableWrapper/index.js';
import { useMaturityLevel } from '../../component-maturity.js';

const Table = ({ className, compact, ...props }) => {
  useMaturityLevel('stable', 'Table');
  const tableClasses = classnames('particles-table', className, {
    'particles-table--compact': !!compact
  });
  return <table className={tableClasses} {...props} />;
};

Table.propTypes = {
  /** Reducing spacing to make table smaller */
  compact: PropTypes.bool
};

Table.defaultProps = {
  compact: false
};

Table.displayName = 'Table';

Table.Body = Body;
Table.Cell = Cell;
Table.Header = Header;
Table.Overlay = Overlay;
Table.Row = Row;
Table.Wrapper = Wrapper;

export default Table;

export { Body, Cell, Header, Overlay, Row, Wrapper };

export const { propTypes, defaultProps, displayName } = Table;
