import Input from './Input/index.js';
import Group from './Group/index.js';
import Form from './Form.js';
import ReduxFormAdapter from './ReduxFormAdapter.js';

Form.Input = Input;
Form.Group = Group;
Form.ReduxForm = ReduxFormAdapter;

export default Form;

export { Input, Group, ReduxFormAdapter as ReduxForm };

