import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useMaturityLevel } from '../../component-maturity.js';

const baseClass = () => 'particles-link';
const classes = (className, { disabled, cardHeader, truncated }) => _.uniq(_.compact([
  baseClass(),
  className,
  cardHeader && 'particles-link--card-header',
  truncated && 'particles-link--truncated',
  disabled && 'particles-link--disabled'
])).join(' ');
const handleClick = (disabled, onClick) => disabled ? _.method('preventDefault') : onClick;

export const Link = ({ as: Component, className, disabled, truncated, onClick, cardHeader, ...rest }) => {
  useMaturityLevel('stable', 'Link');
  return (
    <Component onClick={handleClick(disabled, onClick)} className={classes(className, { disabled, truncated, cardHeader })} {...rest} />
  );
};

Link.defaultProps = { as: 'a', disabled: false, className: 'particles-link' };
Link.displayName = 'Link';
Link.propTypes = {
  /** Render as this kind of component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string,
  /** Link event handler */
  onClick: PropTypes.func,
  /** Marks as card-header, adds extra styling */
  cardHeader: PropTypes.bool,
  /** Marks as truncated, limits max width and adds ellipsis */
  truncated: PropTypes.bool,
  /** Marks as disabled, ignores clicks */
  disabled: PropTypes.bool
};

export default Link;
