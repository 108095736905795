import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Downshift from 'downshift';
import Text from '../Text/index.js';
import Link from '../Link/index.js';
import { NavigationTypeContext, useNavigationType } from '../context.js';
import { useMaturityLevel } from '../../../component-maturity.js';


const Dropdown = props => {
  useMaturityLevel('stable', 'Navigation.Dropdown');
  const {
    children,
    action,
    actionClass,
    dropdownClass,
    className,
    caret,
    utility,
    context,
    right,
    active,
    ...rest
  } = props;
  const navigationType = useNavigationType(props);
  const classes = isOpen =>
    classnames(
      'particles-navigation-dropdown',
      `particles-navigation-dropdown--${navigationType}`,
      className, {
        [`particles-navigation-dropdown--${navigationType}-active`]: !!active,
        [`particles-navigation-dropdown--${navigationType}-open`]: !!isOpen,
        'particles-navigation-dropdown--active': !!active,
        'particles-navigation-dropdown--open': !!isOpen
      }
    );
  const actionClasses = isOpen =>
    classnames(
      'particles-navigation-dropdown__action',
      `particles-navigation-dropdown__action--${navigationType}`,
      actionClass, {
        [`particles-navigation-dropdown__action--${navigationType}-active`]: !!isOpen,
        'particles-navigation-dropdown__action--active': !!isOpen
      }
    );
  const dropdownMenuClasses = classnames(
    'particles-navigation-dropdown__menu',
    `particles-navigation-dropdown__menu--${navigationType}`,
    dropdownClass, {
      [`particles-navigation-dropdown__menu--${navigationType}-right`]: !!right,
      'particles-navigation-dropdown__menu--right': !!right
    }
  );
  const caretClasses = isOpen => classnames(
    'particles-navigation-dropdown__caret',
    `particles-navigation-dropdown__caret--${navigationType}`,
    {
      'particles-navigation-dropdown__caret--closed': !isOpen,
      'particles-navigation-dropdown__caret--open': !!isOpen,
      [`particles-navigation-dropdown__caret--${navigationType}-open`]: !!isOpen,
      [`particles-navigation-dropdown__caret--${navigationType}-closed`]: !isOpen
    }
  );

  return (
    <NavigationTypeContext {...props}>
      <Downshift {...rest}>
        {({
          getItemProps,
          getToggleButtonProps,
          isOpen
        }) => (<div className={classes(isOpen)}>
          <div tabIndex="0" className={actionClasses(isOpen)} {...getToggleButtonProps()}>
            {action} { caret && <i className={`fa fa-chevron-down ${caretClasses(isOpen)}`} aria-hidden="true" /> }
          </div>
          {isOpen ? (
            <div className={dropdownMenuClasses}>
              {React.Children.map(children, (child, i) => {
                if (!child || child.type === Text || !child.type) {
                  return child;
                }
                if (child && child.type === Link) {
                  return React.cloneElement(child, {
                    ...getItemProps({item: child})
                  });
                }
                return React.cloneElement(child, { getItemProps });
              })}
            </div>
          ) : null}
        </div>
        )}
      </Downshift>
    </NavigationTypeContext>
  );
};

const DropdownGroup = (props) => {
  useMaturityLevel('stable', 'Navigation.Dropdown.Group');
  const {
    as,
    className,
    children,
    getItemProps,
    ...rest
  } = props;
  const navigationType = useNavigationType(props);
  const classes = () =>
    classnames(
      'particles-navigation-dropdown-group',
      `particles-navigation-dropdown-group--${navigationType}`,
      className
    );
  const Component = as;
  return (
    <NavigationTypeContext {...props}>
      <Component className={classes()} {...rest}>
        {React.Children.map(children, (child, i) => {
          if (!child || child.type === Text || !child.type) {
            return child;
          }
          if (child && child.type === Link) {
            return React.cloneElement(child, { ...getItemProps({ item: child }) });
          }
          return React.cloneElement(child, { getItemProps, ...rest });
        })}
      </Component>
    </NavigationTypeContext>
  );
};
Dropdown.Group = DropdownGroup;

const DropdownItem = (props) => {
  useMaturityLevel('stable', 'Navigation.Dropdown.Item');
  const {
    as,
    className,
    children,
    getItemProps,
    ...rest
  } = props;
  const navigationType = useNavigationType(props);
  const classes = () =>
    classnames(
      'particles-navigation-dropdown-item',
      `particles-navigation-dropdown-item--${navigationType}`,
      className
    );
  const Component = as;
  return (
    <NavigationTypeContext {...props}>
      <Component className={classes()} {...rest}>
        {React.Children.map(children, (child, i) => {
          if (!child || child.type === Text || !child.type) {
            return child;
          }
          if (child && child.type === Link) {
            return React.cloneElement(child, {
              ...getItemProps({item: child})
            });
          }
          return React.cloneElement(child, { getItemProps, ...rest });
        })}
      </Component>
    </NavigationTypeContext>
  );
};
Dropdown.Item = DropdownItem;

const DropdowItemLabel = (props) => {
  useMaturityLevel('stable', 'Navigation.Dropdown.Item.Label');
  const {
    as,
    className,
    getItemProps,
    ...rest
  } = props;
  const navigationType = useNavigationType(props);
  const classes = () =>
    classnames(
      `particles-navigation-dropdown-item__label--${navigationType}`,
      'particles-navigation-dropdown-item__label',
      className
    );
  const Component = as;
  return (
    <NavigationTypeContext {...props}>
      <Component className={classes()} {...rest} />
    </NavigationTypeContext>
  );
};
Dropdown.Item.Label  = DropdowItemLabel;

const DropdownItemValue = (props) => {
  useMaturityLevel('stable', 'Navigation.Dropdown.Item.Value');
  const {
    as,
    className,
    getItemProps,
    children,
    ...rest
  } = props;
  const navigationType = useNavigationType(props);
  const classes = () =>
    classnames(
      'particles-navigation-dropdown-item__value',
      `particles-navigation-dropdown-item__value--${navigationType}`,
      className
    );
  const Component = as;
  return (
    <NavigationTypeContext {...props}>
      <Component className={classes()} {...rest}>
        {React.Children.map(children, (child, i) => {
          if (!child || child.type === Text || !child.type) {
            return child;
          }
          if (child && child.type === Link) {
            return React.cloneElement(child, {
              ...getItemProps({item: child})
            });
          }
          return React.cloneElement(child, { getItemProps, ...rest });
        })}
      </Component>
    </NavigationTypeContext>
  );
};
Dropdown.Item.Value = DropdownItemValue;

const DropdownGroupHeader = (props) => {
  const {
    as,
    className,
    getItemProps,
    ...rest
  } = props;
  useMaturityLevel('stable', 'Navigation.Dropdown.Group.Header');
  const navigationType = useNavigationType(props);
  const classes = () =>
    classnames(
      'particles-navigation-dropdown-group__header',
      `particles-navigation-dropdown-group__header--${navigationType}`,
      className
    );
  const Component = as;
  return (
    <NavigationTypeContext {...props}>
      <Component className={classes()} {...rest} />
    </NavigationTypeContext>
  );
};
Dropdown.Group.Header = DropdownGroupHeader;

Dropdown.Group.defaultProps = {
  className: '',
  as: 'div'
};
Dropdown.Group.propTypes = {
  /** String or function to transform the component into. */
  as: (...args) => PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType
  ])(...args)
};
Dropdown.Group.displayName = 'Dropdown.Group';

Dropdown.Group.Header.defaultProps = {
  className: '',
  as: 'span'
};
Dropdown.Group.Header.propTypes = {
  /** String or function to transform the component into. */
  as: (...args) => PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType
  ])(...args)
};
Dropdown.Group.Header.displayName = 'Dropdown.Group.Header';

Dropdown.Item.defaultProps = {
  className: '',
  as: 'div'
};
Dropdown.Item.propTypes = {
  /** String or function to transform the component into. */
  as: (...args) => PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType
  ])(...args)
};
Dropdown.Item.displayName = 'Dropdown.Item';

Dropdown.Item.Label.propTypes = {
  /** String or function to transform the component into. */
  as: (...args) => PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType
  ])(...args)
};
Dropdown.Item.Label.defaultProps = {
  className: '',
  as: 'span'
};
Dropdown.Item.Label.displayName = 'Dropdown.Item.Label';

Dropdown.Item.Value.propTypes = {
  /** String or function to transform the component into. */
  as: (...args) => PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType
  ])(...args)
};
Dropdown.Item.Value.defaultProps = {
  className: '',
  as: 'span'
};
Dropdown.Item.Value.displayName = 'Dropdown.Item.Value';


Dropdown.propTypes = {
  /** Dropdown Menu Content */
  children: PropTypes.node.isRequired,
  /** Dropdown text or component */
  action: PropTypes.node.isRequired,
  /** Enable caret content */
  caret: PropTypes.bool,
  /** Enable utility style */
  utility: PropTypes.bool,
  /** Enable context style */
  context: PropTypes.bool,
  /** Enable right alignment of dropdown menu */
  right: PropTypes.bool,
  /** Extra classes */
  className: PropTypes.string,
  /** Extra classes for dropdown content */
  dropdownClass: PropTypes.string,
  /** Extra classes for action content */
  actionClass: PropTypes.string,
  /** Downshift prop for converting item to a string value */
  itemToString: PropTypes.func
};

Dropdown.defaultProps = {
  caret: false,
  itemToString: i => i ? i.key || String(i) : ''
};

export default Dropdown;
