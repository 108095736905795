import React from 'react';
import ModalContext from '../context.js';
import PropTypes from 'prop-types';
import Button from '../../../components/Button/index.js';

const Header = ({ children, title }) => {
  const {onClose, closeDisabled} = React.useContext(ModalContext);
  const contents = (children || title) ? (children || (<h4 className="hx particles">{title}</h4>)) : null;
  return contents ? (
    <header className="particles-modal__header">
      <Button className="particles-modal__close" tertiary size="compact" disabled={closeDisabled} onClick={onClose}>
        <i className="fa fa-times" />
      </Button>
      {contents}
    </header>
  ) : null;
};

Header.displayName = 'Modal.Header';

Header.propTypes = {
  /** title - if exists add a title element to the modal */
  title: PropTypes.node
};


export default Header;

export const { displayName } = Header;
