import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../Table/index.js';
import _ from 'lodash';
import safeStringify from 'fast-safe-stringify';

const DatagridBody = ({
  pageData,
  compact
}) => (
  <Table.Body>
    {_.map(pageData, (row, index) => {
      return (
        <Table.Row key={index}>
          {_.map(row, (column, name) => {
            const cellProps = {
              compact,
              cellName: name
            };
            return (
              <Table.Cell key={name} {...cellProps}>
                {(
                  _.isString(column) ||
                  _.isNumber(column) ||
                  _.isArray(column) ||
                  React.isValidElement(column)
                ) ? column : safeStringify(column)}
              </Table.Cell>
            );
          })}
        </Table.Row>
      );
    })}
  </Table.Body>
);

DatagridBody.propTypes = {
  pageData: PropTypes.arrayOf(PropTypes.object),
  compact: PropTypes.bool
};

DatagridBody.defaultProps = {
  pageData: [],
  compact: false
};

DatagridBody.displayName = 'DatagridBody';

export default DatagridBody;

export const {
  propTypes,
  defaultProps,
  displayName
} = DatagridBody;
