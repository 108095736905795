import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Bar from '../Bar/index.js';
import { useMaturityLevel } from '../../../component-maturity.js';
import { NavigationTypeContext, useNavigationType } from '../context.js';

const Utility = (props) => {
  useMaturityLevel('stable', 'Navigation.Utility');
  const { className, as: Component, ...rest } = props;
  const navigationType = useNavigationType({ ...props, utility: true });
  const utilityClasses = classnames(
    'particles-utility-navigation',
    `particles-navigation-${navigationType}`,
    className
  );
  const containerClasses = classnames('particles-utility-navigation__container');
  return (
    <NavigationTypeContext {...props} utility>
      <Component className={utilityClasses} containerClassName={containerClasses} {...rest} />
    </NavigationTypeContext>
  );
};

Utility.defaultProps = {
  as: Bar
};
Utility.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div', 'section', 'nav' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Utility.displayName = 'Navigation.Utility';

export default Utility;

export const {
  defaultProps,
  propTypes,
  displayName
} = Utility;
