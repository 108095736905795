import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TableCell = ({
  className,
  compact,
  cellName,
  header,
  sorted,
  sortable,
  withMenu,
  cog,
  ...props
}) => {
  const classes = classnames('particles-table__table-cell', className, {
    'particles-table__table-cell--with-menu': !!withMenu,
    'particles-table__table-cell--cog': !!cog,
    'particles-table__table-cell--compact': !!compact,
    [`particles-table__table-cell--${cellName}`]: !!cellName,
    'particles-table__table-cell--header': !!header,
    'particles-table__table-cell--sorted-by': !!sorted,
    'particles-table__table-cell--sortable-by': !!sortable
  });
  const Tag = !!header ? 'th' : 'td';
  return <Tag className={classes} {...props} />;
};

TableCell.propTypes = {
  /** Renders "th" element instead of td & header class */
  header: PropTypes.bool,
  /** Reducing spacing to make table smaller */
  compact: PropTypes.bool,
  /** Sets custom cell className */
  cellName: PropTypes.string,
  /** Adds sorted by className when this cell's column is sorted */
  sorted: PropTypes.bool,
  /** Adds sortable by className when this cell's column can be sorted */
  sortable: PropTypes.bool
};

TableCell.defaultProps = {
  compact: false,
  header: false,
  sorted: false,
  sortable: false
};

TableCell.displayName = 'Table.Cell';

export default TableCell;

export const { propTypes, defaultProps, displayName } = TableCell;
