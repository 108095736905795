import React from 'react';

const createContext = () => ({
  dropdownState: React.createContext({}),
  dropdownType: React.createContext(null)
});

const context = createContext();

export { context };
export default context;
