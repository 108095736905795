import React from 'react';
import context from './context.js';
import _ from 'lodash';

export const Input = ({ value, onChange, disabled, invalid, checked, defaultChecked, ...propsValues }) => {
  const { select, ...contextValues } = React.useContext(context.tiles);
  const { multi, name } = _.merge(contextValues, propsValues);
  const inputType = (multi) => multi ? 'checkbox' : 'radio';
  const aggregatedOnChange = (e) => {
    onChange(e);
    select(e);
  };
  return (
    <input
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={!!disabled}
      name={name}
      className={`particles-choice-tile__input ${invalid ? 'hx--invalid' : ''}`}
      value={value}
      type={inputType(multi)}
      onChange={aggregatedOnChange}
    />
  );
};
Input.displayName = 'ChoiceTiles.Input';

export default Input;
