import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useMaturityLevel } from '../../component-maturity.js';

const baseClass = () => 'particles-tabset';
const classes = (className, { container, list, tab, content, panel, selected }) => _.uniq(_.compact([
  baseClass(),
  className,
  container && 'particles-tabset__container',
  list && 'particles-tabset__list',
  tab && 'particles-tabset__tab',
  tab && selected && 'particles-tabset__tab--selected',
  content && 'particles-tabset__content',
  panel && 'particles-tabset__panel'
])).join(' ');

export const List = ({ className, children, selected, tabClick }) => {
  useMaturityLevel('feedback', 'Tabset.List');
  return (
    <div className={classes(className, { list: true })}>
      {
        React.Children.map(_.filter(children), (child, index) => (
          React.cloneElement(child, {
            index,
            tabClick,
            isSelected: index === selected
          })
        ))
      }
    </div>
  );
};

export const Tab = ({ className, children, index, isSelected, tabClick }) => {
  useMaturityLevel('feedback', 'Tabset.Tab');
  return (
    <div
      className={classes(className, { tab: true, selected: isSelected })}
      onClick={() => tabClick(index)}
    >{children}</div>
  );
};

export const Content = ({ className, children, selected }) => {
  useMaturityLevel('feedback', 'Tabset.Content');
  return (
    <div className={classes(className, { content: true })}>
      {
        React.Children.map(_.filter(children), (child, index) => index === selected ? child : null)
      }
    </div>
  );
};

export const Panel = ({ className, children }) => {
  useMaturityLevel('feedback', 'Tabset.Panel');
  return (
    <div className={classes(className, { panel: true })}>
      { children }
    </div>
  );
};

export const Tabset = ({ className, children, selectedTabIndex, onSelectedTabChange }) => {
  useMaturityLevel('feedback', 'Tabset');
  const [selectedState, setSelected] = React.useState(0);
  const selected = selectedTabIndex !== undefined ? selectedTabIndex : selectedState;
  const tabClick = _.isFunction(onSelectedTabChange) ? onSelectedTabChange : setSelected;

  return (
    <div className={classes(className, { container: true })}>
      {
        React.Children.map(_.filter(children), child => React.cloneElement(child, { selected, tabClick }))
      }
    </div>
  );
};

Tabset.displayName = 'Tabset';
Tabset.List = List;
Tabset.Tab = Tab;
Tabset.Content = Content;
Tabset.Panel = Panel;

Tabset.defaultProps = { className: 'particles-tabset' };

Tabset.propTypes = {
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string,
  /** Tab selection event handler */
  onSelectedTabChange: PropTypes.func,
  /** Index of the currently selected tab */
  selectedTabIndex: PropTypes.number
};

export default Tabset;
