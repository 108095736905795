import React from 'react';
import _ from 'lodash';
import Tippy from '@tippy.js/react';
import { useMaturityLevel } from '../../component-maturity.js';

const Tooltip = ({ children, ...rest }) => {
  useMaturityLevel('unstable', 'Tooltip');
  const safeChildren = _.isString(children) ? <span className="particles-tooltip__trigger">{children}</span> : children;
  return (
    <Tippy theme="light-border" {...rest} children={safeChildren} className="particles-tooltip" />
  );
};


export default Tooltip;
