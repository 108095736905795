import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { fromNow, format, timezoneAbbreviation, timezoneOffset } from '../functions.js';
import { withT } from '../../../translations/index.js';
import { useMaturityLevel } from '../../../component-maturity.js';

const FromNow = withT(({ t, value, preset, target, maxRelativePastDeltaInSeconds } = {}) => {
  useMaturityLevel('stable', 'FromNow');
  return (
    <span
      className={`particles-from-now particles-from-now--${preset}`}
      title={`${format(value, preset, t)} - ${timezoneAbbreviation(value)} (UTC ${timezoneOffset(value)})`}
    >
      {fromNow(value, {
        t,
        presetName: preset,
        now: target || moment.utc(),
        maxRelativePastDeltaInSeconds
      })}
    </span>
  );
});

FromNow.propTypes = {
  /** Current DateTime - anything that can be passed to moment */
  value: PropTypes.any,
  /** Target DateTime - anything that can be passed to moment - defaults to "now" */
  target: PropTypes.any,
  /** Maximum amount of time to go back before just showing the full date - defaults to 0 */
  maxRelativePastDeltaInSeconds: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

FromNow.defaultProps = {
  maxRelativePastDeltaInSeconds: 0
};

FromNow.displayName = 'FromNow';

export default FromNow;
export const {
  propTypes,
  displayName
} = FromNow;
