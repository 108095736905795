import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import FromNow from './FromNow/index.js';
import { fromNow, format, timezoneAbbreviation, timezoneOffset } from './functions.js';
import { customFormats, withT } from '../../translations/index.js';
import { useMaturityLevel } from '../../component-maturity.js';

const DateTime = withT(({ value, preset, t } = {}) => {
  useMaturityLevel('stable', 'DateTime');
  return (
    <span
      className={`particles-date-time particles-date-time--${preset}`}
      title={`${timezoneAbbreviation(value, t)} (UTC ${timezoneOffset(value, t)})`}
    >
      {format(value, preset, t)}
    </span>
  );
});

DateTime.format = format;
DateTime.timezoneOffset = timezoneOffset;
DateTime.timezoneAbbreviation = timezoneAbbreviation;
DateTime.fromNow = fromNow;
DateTime.FromNow = FromNow;

DateTime.displayName = 'DateTime';
DateTime.propTypes = {
  /** Current DateTime - anything that can be passed to moment */
  value: PropTypes.any,
  /** Preset to show: date, date-time, date-time-short, date-short, time, and custom formats defined in translations */
  preset: PropTypes.oneOf(_.concat(
    ['date', 'date-time', 'date-time-short', 'date-short', 'time'],
    _.keys(customFormats().date))
  )
};
DateTime.defaultProps = {
  preset: 'date-time'
};

export { DateTime, FromNow, format, timezoneOffset, timezoneAbbreviation, fromNow };
export default DateTime;
export const {
  propTypes,
  defaultProps,
  displayName
} = DateTime;
