import React from 'react';
import Group from './Group.js';

const ReduxFormGroupAdapter = (props) => {
  const { input, meta, ...rest } = props;

  // eslint-disable-next-line
  console.warn(`
================================================================================================================================================
*** DEPRECATION WARNING ***

Support for redux-form has been deprecated as of janus-particles 5.0.0.

The <Form.ReduxForm> and <Input.ReduxForm> adapters will be removed in a 5.x.x release.
If you are relying on those, please consider either porting the adapter code over, or writing your own layer to communicate with redux-form.

================================================================================================================================================
`, `Please check: ${new Error().stack}`);

  return (
    <Group
      {...rest}
      {...(input || {})}
      {...(meta || {})}
    />
  );
};

ReduxFormGroupAdapter.displayName = 'ReduxFormGroupAdapter';

export default ReduxFormGroupAdapter;

export const {
  displayName
} = ReduxFormGroupAdapter;
