import React from 'react';
import Downshift from 'downshift';
import moment from 'moment';
import Button from '../Button/index.js';
import Collection from '../Collection/index.js';
import ButtonGroup from '../ButtonGroup/index.js';
import Grid from '../Grid/index.js';
import Form from '../Form/index.js';
import _ from 'lodash';
import DateTime from 'react-datetime';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withT } from '../../translations/index.js';
import { useMaturityLevel } from '../../component-maturity.js';

const CARET = (
  <i className="fa fa-chevron-down particles-date-time-picker-dropdown__caret" aria-hidden="true" />
);

const TIME_REGEX = [
  '[\\s\\t]*',
  '([0-9]|[0-1][0-9]|[2][0-3]):',
  '([0-9]|[0-5][0-9]):',
  '([0-9]|[0-5][0-9])',
  '[\\s\\t]*'
].join('');

const renderDay = context => (dayProps, currentDate) => {
  const dayClasses = classnames(dayProps.className, {
    'particles-date-time-picker__day--last-in-range': (
      context.moment(currentDate).startOf('day').format() ===
      context.moment(context.end).startOf('day').format()
    ),
    'particles-date-time-picker__day--first-in-range': (
      context.moment(currentDate).startOf('day').format() ===
      context.moment(context.start).startOf('day').format()
    ),
    'particles-date-time-picker__day--in-range': (
      context.moment(currentDate).startOf('day').format() <=
      context.moment(context.end).startOf('day').format()
    ) && (
      context.moment(currentDate).startOf('day').format() >=
      context.moment(context.start).startOf('day').format()
    )
  });
  return (
    <td {...dayProps} className={dayClasses}>
      {currentDate.date()}
    </td>
  );
};


const DateTimePicker = withT(({ t, ...props }) => {
  useMaturityLevel('unstable', 'DateTimePicker');
  const [timezone, setTimezone] = React.useState(props.timezone);

  const momentInTimezone = React.useCallback(raw => (timezone === 'utc') ? moment.utc(raw) : moment.utc(raw).local(), [timezone]);
  const rawTime = raw => momentInTimezone(raw).format('HH:mm:ss');

  const [selectedPreset, setSelectedPreset] = React.useState(props.selectedPreset);
  const [start, setStart] = React.useState(momentInTimezone(props.start));
  const [end, setEnd] = React.useState(momentInTimezone(props.end));

  const onChange = (newState) => {
    _.invoke(props, 'onChange', newState);
    setStart(newState.start);
    setEnd(newState.end);
  };

  const onSubmit = closeMenu => (e) => {
    closeMenu();
    _.invoke(e, 'preventDefault');
    _.invoke(props, 'onApply', {
      selectedPreset,
      start,
      end
    });
  };

  const onCancel = closeMenu => (e) => {
    closeMenu();
    _.invoke(e, 'preventDefault');
    _.invoke(props, 'onCancel');
  };

  const {
    align,
    label,
    utcTimeLabel,
    localTimeLabel,
    formatLabel,
    startLabel,
    endLabel,
    applyLabel,
    cancelLabel,
    caret,
    fallbackPreset,
    presets,
    ...rest
  } = props;
  const menuClasses = classnames('particles-date-time-picker-dropdown__menu', {
    'particles-date-time-picker-dropdown__menu--left-aligned': !!(align === 'left'),
    'particles-date-time-picker-dropdown__menu--right-aligned': !(align === 'left')
  });
  return (
    <div className="particles-date-time-picker">
      <Downshift {...rest}>
        {(downshiftProps) => {
          const {
            getToggleButtonProps,
            isOpen,
            closeMenu
          } = downshiftProps;
          const triggerClasses = classnames('particles-date-time-picker-dropdown__trigger', {
            'particles-date-time-picker-dropdown__trigger--open': !!isOpen,
            'particles-date-time-picker-dropdown__trigger--closed': !isOpen
          });
          const max = props.max ? momentInTimezone(props.max) : null;
          const min = props.min ? momentInTimezone(props.min) : null;
          return (
            <div className="particles-date-time-picker-dropdown__wrapper">
              <Button type="button" primary tabIndex="0" {...getToggleButtonProps()} className={triggerClasses}>
                {label} {caret && CARET}
              </Button>
              {isOpen ? (
                <div className={menuClasses}>
                  <Form onSubmit={onSubmit(closeMenu)} size="unbound" enableHtmlValidation>
                    <div className="particles-date-time-picker-dropdown__presets">
                      <Grid>
                        <Grid.Cell auto>
                          {_.some(_.compact(presets)) ? (
                            <ButtonGroup>
                              {_.map(_.compact(presets), (preset, index) => (
                                <Button
                                  type="button"
                                  secondary
                                  size="compact"
                                  selected={selectedPreset === index}
                                  key={preset.label}
                                  onClick={(e) => {
                                    _.invoke(e, 'preventDefault');
                                    setSelectedPreset(index);
                                    onChange({
                                      start: momentInTimezone(_.isFunction(preset.start) ? preset.start() : preset.start),
                                      end: momentInTimezone(_.isFunction(preset.end) ? preset.end() : preset.end)
                                    });
                                }}>{preset.label}</Button>
                              ))}
                              {fallbackPreset ? (
                                <Button
                                  type="button"
                                  secondary
                                  size="compact"
                                  selected={selectedPreset < 0}
                                  key={fallbackPreset}
                                  onClick={(e) => {
                                    _.invoke(e, 'preventDefault');
                                    setSelectedPreset(-1);
                                }}>{fallbackPreset}</Button>
                              ) : null}
                            </ButtonGroup>
                          ) : null}
                        </Grid.Cell>
                        <Grid.Cell auto className="particles-date-time-picker-dropdown__timezones">
                          <ButtonGroup>
                            <Button
                              title={`UTC${moment.utc().local().format('Z')}`}
                              type="button"
                              secondary
                              size="compact"
                              selected={timezone === 'local'}
                              key="local"
                              onClick={(e) => {
                                _.invoke(e, 'preventDefault');
                                setTimezone('local');
                              }}
                            >{localTimeLabel}</Button>
                            <Button
                              type="button"
                              secondary
                              size="compact"
                              selected={timezone === 'utc'}
                              key="utc"
                              onClick={(e) => {
                                _.invoke(e, 'preventDefault');
                                setTimezone('utc');
                              }}
                            >{utcTimeLabel}</Button>
                          </ButtonGroup>
                        </Grid.Cell>
                      </Grid>
                    </div>
                    <Grid withGutters className="particles-date-time-picker-dropdown__calendars">
                      <Grid.Cell auto>
                        <div className="particles-date-time-picker__calendar-label-wrapper">
                          <span className="particles-date-time-picker__calendar-label">
                            {startLabel}
                          </span>
                          <span className="particles-date-time-picker__calendar-selected-value">
                            {t('particlesDateShort', { date: momentInTimezone(start).toDate() })}
                          </span>
                        </div>
                        <DateTime
                          className="particles-date-time-picker__calendar"
                          locale={t.locale}
                          input={false}
                          open
                          value={momentInTimezone(start)}
                          timeFormat={false}
                          renderDay={renderDay({ moment: momentInTimezone, start, end })}
                          isValidDate={date => ((
                            !min || (
                              momentInTimezone(date).startOf('day').format() >=
                               momentInTimezone(min).startOf('day').format()
                            )
                          ) && (
                            momentInTimezone(date).startOf('day').format() <=
                              momentInTimezone(end).startOf('day').format()
                          ))}
                          onChange={(newStart) => {
                            setSelectedPreset(-1);
                            onChange({
                              start: moment.utc(newStart),
                              end: moment.utc(end)
                            });
                          }}
                        />
                        <Form.Input
                          value={rawTime(start)}
                          onChange={(e) => {
                            const newStart = momentInTimezone(start);
                            const value = _.trim(_.get(e, 'target.value'));
                            setSelectedPreset(-1);
                            if (new RegExp(TIME_REGEX).test(value)) {
                              const [hours, minutes, seconds] = value.split(':');
                              newStart.hours(+hours);
                              newStart.minutes(+minutes);
                              newStart.seconds(+seconds);
                              setStart(newStart);
                            }
                          }}
                          help={formatLabel}
                          placeholder="12:13:14"
                          className="particles-date-time-picker__time-input"
                          required
                          name="startTime"
                          type="text"
                          pattern={TIME_REGEX}
                        />
                      </Grid.Cell>
                      <Grid.Cell auto>
                        <div className="particles-date-time-picker__calendar-label-wrapper">
                          <span className="particles-date-time-picker__calendar-label">
                            {endLabel}
                          </span>
                          <span className="particles-date-time-picker__calendar-selected-value">
                            {t('particlesDateShort', { date: momentInTimezone(end).toDate() })}
                          </span>
                        </div>
                        <DateTime
                          className="particles-date-time-picker__calendar"
                          locale={t.locale}
                          input={false}
                          open
                          value={momentInTimezone(end)}
                          timeFormat={false}
                          renderDay={renderDay({ moment: momentInTimezone, start, end })}
                          isValidDate={date => ((
                            !max || (
                              momentInTimezone(date).startOf('day').format() <=
                                momentInTimezone(max).startOf('day').format()
                            )
                          ) && (
                            momentInTimezone(date).startOf('day').format() >=
                              momentInTimezone(start).startOf('day').format()
                          ))}
                          onChange={(newEnd) => {
                            setSelectedPreset(-1);
                            onChange({
                              start: moment.utc(start),
                              end: moment.utc(newEnd)
                            });
                          }}
                        />
                        <Form.Input
                          value={rawTime(end)}
                          onChange={(e) => {
                            const newEnd = momentInTimezone(end);
                            const value = _.trim(_.get(e, 'target.value'));
                            setSelectedPreset(-1);
                            if (new RegExp(TIME_REGEX).test(value)) {
                              const [hours, minutes, seconds] = value.split(':');
                              newEnd.hours(+hours);
                              newEnd.minutes(+minutes);
                              newEnd.seconds(+seconds);
                              setEnd(newEnd);
                            }
                          }}
                          help={formatLabel}
                          placeholder="12:13:14"
                          className="particles-date-time-picker__time-input"
                          required
                          name="endTime"
                          type="text"
                          pattern={TIME_REGEX}
                        />
                      </Grid.Cell>
                    </Grid>
                    <Collection>
                      <Button type="submit" primary>
                        {applyLabel}
                      </Button>
                      <Button type="button" secondary onClick={onCancel(closeMenu)}>
                        {cancelLabel}
                      </Button>
                    </Collection>
                  </Form>
                </div>
              ) : null}
            </div>
          );
        }}
      </Downshift>
    </div>
  );
});

DateTimePicker.propTypes = {
  /** Show caret next to the label */
  carent: PropTypes.bool,
  /** Max date allowed to be selected on the "to" calendar */
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Min date allowed to be selected on the "from" calendar */
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Label for the "UTC" timezone button */
  utcTimeLabel: PropTypes.node,
  /** Label for the "Local" timezone button */
  localTimeLabel: PropTypes.node,
  /** Label describing the format required for time input (HH:mm:ss) */
  formatLabel: PropTypes.node,
  /** Label for the "from" calendar */
  startLabel: PropTypes.node,
  /** Label for the "to" calendar */
  endLabel: PropTypes.node,
  /** Label for the button */
  label: PropTypes.node,
  /** Label for confirm button */
  applyLabel: PropTypes.node,
  /** Label for cancel button */
  cancelLabel: PropTypes.node,
  /** List of pre-canned presets to offer for date/time selection */
  presets: PropTypes.array,
  /** Label for the fallback preset */
  fallbackPreset: PropTypes.node,
  /** Currently selected preset (numeric index, -1 for fallback) */
  selectedPreset: PropTypes.number,
  /** Default timezone to show time in (local or utc) */
  timezone: PropTypes.string,
  /** Which side to align the dropdown with (left or right) */
  align: PropTypes.string
};
DateTimePicker.defaultProps = {
  caret: false,
  max: undefined,
  min: undefined,
  align: 'right',
  utcTimeLabel: 'UTC',
  localTimeLabel: 'Local',
  formatLabel: 'Time in the format HH:mm:ss',
  startLabel: 'From',
  endLabel: 'To',
  label: 'Time Period',
  applyLabel: 'apply',
  cancelLabel: 'cancel',
  presets: [],
  selectedPreset: -1,
  timezone: 'local'
};

export default DateTimePicker;
