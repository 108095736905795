import React from 'react';
import PropTypes from 'prop-types';
import context from './context.js';

const DropdownTrigger = ({ children, as: Component, ...rest }) => {
  const { isOpen: open, getToggleButtonProps } = React.useContext(context.dropdownState);
  return <Component {...rest} {...getToggleButtonProps({})} {...{ open }}>{children}</Component>;
};
DropdownTrigger.displayName = 'Dropdown.Trigger';
DropdownTrigger.defaultProps = { as: 'span' };
DropdownTrigger.propTypes = {
  /** Render as this kind of component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string
};

export default DropdownTrigger;
