import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import context from './context.js';
import Downshift from 'downshift';
import { useMaturityLevel } from '../../component-maturity.js';

const dropdownClasses = ({ isOpen } = {}) => _.compact([
  'particles-dropdown',
  !isOpen && 'particles-dropdown--closed',
  isOpen && 'particles-dropdown--open'
]).join(' ');
const noDownshiftProps = (props) => ({ getMenuProps: _.identity, getItemProps: _.identity, getToggleButtonProps: _.identity, ...(props || {}) });
const Dropdown = ({ children, open: isOpen, as: Component, manual, ...rest }) => {
  useMaturityLevel('feedback', 'Dropdown');
  const render = (downshiftProps) => (
    <Component className={dropdownClasses({ isOpen })}>
      <context.dropdownState.Provider value={_.merge({}, downshiftProps, { isOpen })}>
        <React.Fragment>
          {children}
        </React.Fragment>
      </context.dropdownState.Provider>
    </Component>
  );
  return manual ? render(noDownshiftProps(rest)) : (<Downshift>{render}</Downshift>);
};

Dropdown.displayName = 'Dropdown';
Dropdown.defaultProps = { as: 'span' };
Dropdown.propTypes = {
  /** Render as this kind of component */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string,
  /** Opt out of using downshift, consumers need to control the state of the dropdown on their own */
  manual: PropTypes.bool
};


export default Dropdown;
