import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useMaturityLevel } from '../../component-maturity.js';

import Body from './ModalBody/index.js';
import Footer from './ModalFooter/index.js';
import Header from './ModalHeader/index.js';
import {Container} from '../Page/index.js';
import {ModalProvider} from './context.js';

const sizes = ['small', 'medium', 'large'];


const contentClasses = ({size}) => classnames('particles-modal--content', {
  [`particles-modal--${size}`]: (sizes.indexOf(size) >= 0)
});

const Modal = ({ children, size, onClose, closeDisabled }) => {
  useMaturityLevel('unstable', 'Modal');
  const modal = useRef(null);
  useEffect(() => {
    try {
      if (modal && modal.current) {
        modal.current.focus();
      }
    } catch (e) {
      // ignore
    }
  }, []);
  if (!children) {
    return null;
  }

  return (
    <ModalProvider value={{onClose, closeDisabled}}>
    <Container>
    <div className={classnames('particles-modal')} tabIndex="0" ref={modal}
      onKeyDown={(e) => {
        if (e.key === 'Escape' && !closeDisabled) {
          onClose();
        }
      }}>
      <div className={contentClasses({ size })}>
        {children}
      </div>
    </div>
    </Container>
    </ModalProvider>
  );
};

Modal.displayName = 'Modal';
Modal.propTypes = {
  /** onClose - close handler that can be passed to the modal */
  onClose: PropTypes.func,

  /** closeDisabled - flag to disable the close button and esc key during processing */
  closeDisabled: PropTypes.bool,

  /** Size of the modal: small, medium, large, unbound */
  size: PropTypes.oneOf(sizes)
};
Modal.defaultProps = {
  size: 'medium'
};

Modal.Header = Header;
Modal.Footer = Footer;
Modal.Body = Body;

export default Modal;
export { Body, Footer, Header };

export const {
  propTypes,
  defaultProps,
  displayName
} = Modal;
