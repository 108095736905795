import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from '../PageContainer/index.js';

const Banner = ({ className, children, ...rest }) => {
  const classes = classnames('particles-page__banner', className);
  return (
    <div className={classes} {...rest}>
      <Container>{children}</Container>
    </div>
  );
};

Banner.propTypes = {
  /** Child components */
  children: PropTypes.node,
  /** Extra classes */
  className: PropTypes.string
};

Banner.defaultProps = {
  className: ''
};

Banner.displayName = 'Page.Banner';

export default Banner;

export const {
  propTypes,
  defaultProps,
  displayName
} = Banner;
