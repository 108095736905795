export const b = (block) => {
  return `particles-${block}`;
};
export const be = (block, element) => {
  return `particles-${block}__${element}`;
};
export const bm = (block, modifier) => {
  return `particles-${block}--${modifier}`;
};
export const bem = (block, element, modifier) => {
  if (modifier && element) {
    return `particles-${block}__${element}--${modifier}`;
  }
  if (element) {
    return be(block, element);
  }
  if (modifier) {
    return bm(block, modifier);
  }
  return b(block);
};

export default bem;

