import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { NavigationTypeContext, useNavigationType } from '../context.js';
import { useMaturityLevel } from '../../../component-maturity.js';

const Separator = (props) => {
  useMaturityLevel('stable', 'Navigation.Text');
  const { as: Component, className, ...rest } = props;
  const navigationType = useNavigationType(props);
  const textClasses = classnames(
    'particles-navigation-separator',
    `particles-navigation-separator--${navigationType}`,
    className
  );
  return (
    <NavigationTypeContext {...props}>
      <Component className={textClasses} {...rest} />
    </NavigationTypeContext>
  );
};

Separator.defaultProps = {
  as: 'span'
};
Separator.propTypes = {
  /** Enable utility styling */
  utility: PropTypes.bool,
  /** Enable primary styling */
  primary: PropTypes.bool,
  /** Enable context styling */
  context: PropTypes.bool,
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Separator.displayName = 'Navigation.Separator';

export default Separator;

export const {
  defaultProps,
  propTypes,
  displayName
} = Separator;
