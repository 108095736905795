import React from 'react';
import _ from 'lodash';

const context = React.createContext({});

const useNavigationType = (props = {}) => {
  const parentNavigationType = _.get(React.useContext(context), 'navigationType');
  const navigationType = _.get(_.find([
    [props.footer, 'footer'],
    [props.context, 'context'],
    [props.primary, 'primary'],
    [props.utility, 'utility']
  ], '0'), '1') || parentNavigationType || 'none';
  return navigationType;
};

const NavigationTypeContext = (props) => {
  const navigationType = useNavigationType(props);
  return props.children ? (
    <context.Provider value={{ navigationType }}>
      {props.children}
    </context.Provider>
  ) : null;
};

export default context;
export { context, NavigationTypeContext, useNavigationType };
