import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

const isCheckable = props => ([
  'checkbox', 'radio'
].indexOf(props.type) >= 0);
const whitelist = [
  'required',
  'type',
  'name',
  'checked',
  'defaultChecked',
  'readOnly',
  'disabled',
  'value',
  'id',
  'onClick',
  'onBlur',
  'onFocus',
  'onChange',
  'pattern'
];

const Checkable = (props = {}) => {
  const { className, type, label, name, value, help, standalone } = props;
  const error = standalone && props.error;
  const inputClasses = classnames(
    'particles-input__input',
    'particles-input__input--meta-checkable',
    `particles-input__input--${type}`,
    `particles-input__input--${name}`,
    `particles-input__input--${name}-${value}`
  );
  const wrapperClasses = classnames(
    'particles-input__wrapper',
    'particles-input__wrapper--meta-checkable',
    `particles-input__wrapper--${type}`,
    `particles-input__wrapper--${name}`,
    `particles-input__wrapper--${name}-${value}`,
    className,
    {
      'particles-input__wrapper--standalone': !!standalone
    }
  );
  return (
    <div className={wrapperClasses}>
      <input
        {..._.pick(props, whitelist)}
        className={inputClasses}
      />
      {label}
      {help}
      {error}
    </div>
  );
};

Checkable.displayName = 'Checkable';
Checkable.isCheckable = isCheckable;
Checkable.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** Input type, set to 'checkbox' */
  type: PropTypes.string,
  /** `name` attribute value */
  name: PropTypes.string,
  /** Label to display with checkbox */
  label: PropTypes.node,
  /** Help message to display with checkbox */
  help: PropTypes.node,
  /** Error message to display when there is an error */
  error: PropTypes.node,
  /** Value to set when checked */
  value: PropTypes.any,
  /** Treat checkbox as a standalone */
  standalone: PropTypes.bool
};

export default Checkable;
export { isCheckable };

export const {
  displayName,
  propTypes
} = Checkable;
