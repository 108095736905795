import _ from 'lodash';
import React from 'react';
import { parse } from 'intl-messageformat-parser';
import memoizeIntlConstructor from 'intl-format-cache';
import * as sentry from '@sentry/browser';
import { context } from '../context.js';
import { forceLocale, locale } from './locale.js';
import safeStringify from 'fast-safe-stringify';

import '@formatjs/intl-pluralrules/polyfill.js';
import '@formatjs/intl-pluralrules/dist/locale-data/en.js';
import '@formatjs/intl-pluralrules/dist/locale-data/ja.js';

import '@formatjs/intl-relativetimeformat/polyfill.js';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en.js';
import '@formatjs/intl-relativetimeformat/dist/locale-data/ja.js';

import IntlMessageFormat from 'intl-messageformat';

const resolveLocale = (configuration) => _.get(configuration, 'locale') || locale() || 'en-US';

const formatters = {
  getNumberFormat: memoizeIntlConstructor(Intl.NumberFormat),
  getDateTimeFormat: memoizeIntlConstructor(Intl.DateTimeFormat),
  getPluralRules: memoizeIntlConstructor(Intl.PluralRules)
};

const customFormats = (configuration) => _.merge({
  date: _.reduce({
    particlesDateTimeShort: { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' },
    particlesDateTime: { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' },
    particlesDateShort: { month: 'short', day: 'numeric', year: 'numeric' },
    particlesDate: { month: 'long', day: 'numeric', year: 'numeric' },
    particlesTime: { hour: 'numeric', minute: 'numeric', second: 'numeric' },
    particlesYear: { year: 'numeric' },
    particlesShortMonthYear: { month: 'short', year: 'numeric' },
    particlesShortMonth: { month: 'short' },
    particlesLongMonthYear: { year: 'numeric', month: 'long' }
  }, (date, value, key) => ({
    ...date, [key]: value, [`${key}Utc`]: _.merge({ timeZone: 'utc' }, value)
  }), {})
}, _.get(configuration, 'customFormats'));

const defaultDateTimeMessages = () => ({
  particlesYear: '{date, date, particlesYear}',
  particlesYearUtc: '{date, date, particlesYearUtc}',
  particlesShortMonthYear: '{date, date, particlesShortMonthYear}',
  particlesShortMonthYearUtc: '{date, date, particlesShortMonthYearUtc}',
  particlesShortMonth: '{date, date, particlesShortMonth}',
  particlesShortMonthUtc: '{date, date, particlesShortMonthUtc}',
  particlesLongMonthYear: '{date, date, particlesLongMonthYear}',
  particlesLongMonthYearUtc: '{date, date, particlesLongMonthYearUtc}',
  particlesDateTimeUtc: '{date, date, particlesDateTimeUtc}',
  particlesDateTime: '{date, date, particlesDateTime}',
  particlesDateTimeShortUtc: '{date, date, particlesDateTimeShortUtc}',
  particlesDateTimeShort: '{date, date, particlesDateTimeShort}',
  particlesDateUtc: '{date, date, particlesDateUtc}',
  particlesDate: '{date, date, particlesDate}',
  particlesDateShortUtc: '{date, date, particlesDateShortUtc}',
  particlesDateShort: '{date, date, particlesDateShort}',
  particlesTimeUtc: '{date, date, particlesTimeUtc}',
  particlesTime: '{date, date, particlesTime}'
});
const defaultPaginationMessages = () => ({
  'Showing {from, number}-{to, number} of {total, number}': 'Showing {from, number}-{to, number} of {total, number}',
  'Show (items per page)': 'Show ',
  '…': '…',
  'Rows': 'Rows'
});
const defaultMessages = () => ({
  ...defaultDateTimeMessages(),
  ...defaultPaginationMessages()
});
const configurationWithDefaultMessages = (...overrides) => _.merge({ messages: defaultMessages() }, ...overrides);

const alwaysReportMissingKeysToSentry = (error, ...args) => {
  sentry.captureException(error, ...args);
};

const whenRunningTestsMakeMissingKeysCauseFailures = (error) => {
  if (global.CRASH_ON_MISSING_TRANSLATIONS) {
    throw error;
  }
};

const handleMissingKey = (key, locale) => {
  const error = new Error(`🥋 missing translation for [key=${key}][locale=${locale}]`);
  error.name = 'MissingTranslation (@janus.team/janus-particles)';
  alwaysReportMissingKeysToSentry(error, { extra: { key, locale } });
  whenRunningTestsMakeMissingKeysCauseFailures(error);
};

const content = (configuration, key) => {
  const messages = _.get(configurationWithDefaultMessages(configuration), 'messages') || {};
  const locale = resolveLocale(configuration);
  const raw = _.get(messages, key);
  const value = _.isString(raw) ? raw : _.get(raw, 'translation');
  if (!value) {
    handleMissingKey(key, locale);
  }
  return value || key;
};

const ast = _.memoize(
  (configuration, key) => parse(content(configuration, key)),
  (...args) => safeStringify(args)
);

const message = _.memoize(
  (configuration, key) => (new IntlMessageFormat(ast(configuration, key), resolveLocale(configuration), customFormats(configuration), { formatters })),
  (...args) => safeStringify(args)
);

const wrap = (configuration, original) => (resolveLocale(configuration) === 'en-coffee') ? (`☕️ ${original} ☕️`) : original;

const t = (configuration, key, ...args) => wrap(configuration, message(configuration, key).format(...args));

const relativeTimeLong = (configuration, ...args) => wrap(
  configuration,
  (new Intl.RelativeTimeFormat(resolveLocale(configuration), { style: 'long', numeric: 'auto' })).format(...args)
);
const relativeTimeShort = (configuration, ...args) => wrap(
  configuration,
  (new Intl.RelativeTimeFormat(resolveLocale(configuration), { style: 'short', numeric: 'auto' })).format(...args)
);
const relativeTimeNumericLong = (configuration, ...args) => wrap(
  configuration,
  (new Intl.RelativeTimeFormat(resolveLocale(configuration), { style: 'long', numeric: 'always' })).format(...args)
);
const relativeTimeNumericShort = (configuration, ...args) => wrap(
  configuration,
  (new Intl.RelativeTimeFormat(resolveLocale(configuration), { style: 'short', numeric: 'always' })).format(...args)
);


const useT = (overrides) => {
  const configuration = _.merge({}, React.useContext(context.configuration), overrides);
  const translateWithConfiguration = _.partial(t, configuration);
  translateWithConfiguration.relativeTimeShort = _.partial(relativeTimeShort, configuration);
  translateWithConfiguration.relativeTimeLong = _.partial(relativeTimeLong, configuration);
  translateWithConfiguration.relativeTimeNumericShort = _.partial(relativeTimeNumericShort, configuration);
  translateWithConfiguration.relativeTimeNumericLong = _.partial(relativeTimeNumericLong, configuration);
  translateWithConfiguration.locale = resolveLocale(configuration);
  translateWithConfiguration.configuration = configuration;
  translateWithConfiguration.customFormats = customFormats(configuration);
  return translateWithConfiguration;
};

const withT = (Component) => (props) => {
  const t = useT();
  return <Component {...props} t={t} />;
};

export default { t, wrap, message, ast, content, handleMissingKey, formatters, useT, withT, customFormats, resolveLocale, forceLocale,locale };
export { t, wrap, message, ast, content, handleMissingKey, formatters, useT, withT, customFormats, resolveLocale, forceLocale, locale };
