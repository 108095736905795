import React from 'react';
import classnames from 'classnames';
import Cell from './Cell/index.js';
import PropTypes from 'prop-types';
import { useMaturityLevel } from '../../component-maturity.js';

const Grid = ({ children, className, style, withGutters, debug, noWrap }) => {
  useMaturityLevel('stable', 'Grid');
  const rowClasses = classnames(
    'particles-grid',
    className,
    'row',
    {
      'particles-grid--no-wrap': !!noWrap,
      'particles-grid--debug': !!debug,
      'no-gutters': !withGutters
    }
  );
  return (
    <div className={rowClasses} style={style}>
      {children}
    </div>
  );
};

Grid.propTypes = {
  /** Child components */
  children: PropTypes.node,
  /** Additional class to apply to component */
  className: PropTypes.string,
  /** Inline style to add to component */
  style: PropTypes.object,
  /** Display grid outline. Useful for seeing where grid boundaries are. */
  debug: PropTypes.bool,
  /** Add gutters to rows */
  withGutters: PropTypes.bool
};

Grid.defaultProps = {
  children: null,
  className: '',
  style: {},
  debug: false,
  withGutters: false
};

Grid.displayName = 'Grid';
Grid.Cell = Cell;

export default Grid;
export { Cell };

export const {
  propTypes,
  defaultProps,
  displayName
} = Grid;
