import _ from 'lodash';
import React from 'react';
import classnames from 'classnames';
import Collection from '../../Collection/index.js';
import PropTypes from 'prop-types';

/** Footer of Card component */
const Footer = (props) => {
  const { children, className, compact, as: Component, ...rest } = props;
  if (!_.some(children)) {
    return null;
  }
  const bodyClasses = classnames(
    'particles-card__footer',
    className,
    {
      'particles-card__footer--compact': !!compact || (Component === Collection)
    }
  );
  return (
    <Component className={bodyClasses} {...rest}>
      {children}
    </Component>
  );
};

Footer.defaultProps = {
  as: 'div',
  compact: false
};
Footer.propTypes = {
  /** Render as this kind of component. ie: [a, Link, Button, ...] */
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.elementType]),
  /** Style with less padding */
  compact: PropTypes.bool,
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component  */
  className: PropTypes.string
};
Footer.displayName = 'Card.Footer';

export default Footer;

export const {
  defaultProps,
  propTypes,
  displayName
} = Footer;
