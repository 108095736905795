import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useT } from '../../../translations/index.js';

const pageWindow = 7;
const pageHalfWindow = 3;

const computeWindow = (currentPage, numberOfPages, tooManyPages) => {
  if (!tooManyPages) {
    return [0, numberOfPages];
  }
  if (currentPage > pageHalfWindow) {
    if ((currentPage + pageHalfWindow) < numberOfPages) {
      return [currentPage - pageHalfWindow, currentPage + pageHalfWindow + 1];
    }
    return [numberOfPages - pageWindow, numberOfPages];
  }
  return [0, pageWindow];
};

const Pagination = ({
  compact,
  numberOfPages,
  numberOfItemsTotal,
  pageSizes,
  pageStart,
  pageEnd,
  itemsPerPage,
  currentPage,
  onCurrentPageChange,
  onItemsPerPageChange
}) => {
  const t = useT();
  if (!+itemsPerPage) {
    return null;
  }
  const paginationClassNames = classnames('particles-table__pagination', {
    'particles-table__pagination--compact': !!compact
  });
  const itemsOfTotalClassNames = classnames('particles-table__items-of-total', {
    'particles-table__items-of-total--compact': !!compact
  });
  const pageSelectorClassNames = classnames('particles-table__page-selector', {
    'particles-table__page-selector--compact': !!compact
  });
  const itemsPerPageClassNames = classnames('particles-table__items-per-page-selector', {
    'particles-table__items-per-page-selector--compact': !!compact
  });
  const tooManyPages = numberOfPages > 10;

  const visiblePages = _.range(...computeWindow(+currentPage, +numberOfPages, tooManyPages));

  const classesFirst = classnames('particles-table__page', 'particles-table__page--first', {
    'particles-table__page--current-page': 0 === +currentPage
  });
  const classesLast = classnames('particles-table__page', 'particles-table__page--last', {
    'particles-table__page--current-page': numberOfPages === +currentPage
  });

  const showLast = tooManyPages && ((currentPage + pageHalfWindow + 1) < numberOfPages);
  const showFirst = tooManyPages && (currentPage > pageHalfWindow);

  return (
    <div className={paginationClassNames}>
      <span className={itemsOfTotalClassNames}>
        {t('Showing {from, number}-{to, number} of {total, number}', { from: +(numberOfItemsTotal ? pageStart + 1 : 0), to: +pageEnd, total: numberOfItemsTotal })}
      </span>
      <span className={pageSelectorClassNames}>
        {showFirst && (
          <button className={classesFirst} onClick={(event) => {
            event.preventDefault();
            onCurrentPageChange(0);
          }}>
            <i className="fa fa-angle-double-left" />
          </button>
        )}
        {showFirst && t('…')}
        {visiblePages.map((page) => {
          const classes = classnames('particles-table__page', {
            'particles-table__page--current-page': page === +currentPage
          });
          return (
            <button key={page} className={classes} onClick={(event) => {
              event.preventDefault();
              onCurrentPageChange(page);
            }}>
              {page + 1}
            </button>
          );
        })}
        {showLast && t('…')}
        {showLast && (
          <button className={classesLast} onClick={(event) => {
            event.preventDefault();
            onCurrentPageChange(numberOfPages - 1);
          }}>
            <i className="fa fa-angle-double-right" />
          </button>
        )}
      </span>
      <span className={itemsPerPageClassNames}>
        {t('Show (items per page)')}
        {_.map(pageSizes, (items) => {
          const classes = classnames('particles-table__items-per-page', {
            'particles-table__items-per-page--current-items-per-page': items === +itemsPerPage
          });
          return (
            <button key={items} className={classes} onClick={(event) => {
              event.preventDefault();
              onItemsPerPageChange(items);
            }}>
              {items}
            </button>
          );
        })}
      </span>
    </div>
  );
};

Pagination.displayName = 'Pagination';

export default Pagination;

export const {
  displayName
} = Pagination;
