import React from 'react';
import PropTypes from 'prop-types';
import Form from './Form.js';
import { useMaturityLevel } from '../../component-maturity.js';

// https://redux-form.com/7.1.2/docs/api/props.md/#-code-props-code-
const reduxFormPropTypes = () => ({
  anyTouched: PropTypes.bool,
  array: PropTypes.object,
  asyncValidate: PropTypes.func,
  asyncValidating: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  autofill: PropTypes.func,
  blur: PropTypes.func,
  change: PropTypes.func,
  clearAsyncError: PropTypes.func,
  destroy: PropTypes.func,
  dirty: PropTypes.bool,
  error: PropTypes.any,
  form: PropTypes.string,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  initialized: PropTypes.bool,
  initialValues: PropTypes.object,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  touch: PropTypes.func,
  untouch: PropTypes.func,
  valid: PropTypes.bool,
  warning: PropTypes.any
});

const ReduxFormAdapter = ({
  children,
  name,
  handleSubmit,
  onSubmit,
  ...rest
} = {}) => {
  useMaturityLevel('deprecated', 'ReduxFormAdapter');
  return (
    <Form onSubmit={handleSubmit} name={name} {...rest}>
      {children}
    </Form>
  );
};

ReduxFormAdapter.propTypes = {
  ...reduxFormPropTypes(),
  name: PropTypes.string,
  children: PropTypes.node,
  enableHtmlValidation: PropTypes.bool
};

ReduxFormAdapter.displayName = 'Form.ReduxFormAdapter';

export default ReduxFormAdapter;

export const {
  propTypes,
  displayName
} = ReduxFormAdapter;
