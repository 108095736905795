import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Cell = (props) => {
  const { children, className, style, auto, verticalGutter, right, ...sizes } = props;
  const cellClasses = classnames(
    'particles-grid__cell',
    className,
    right && 'particles-grid__cell--right',
    'col',
    ['sm', 'md', 'lg', 'xl'].map(size => ({
      [`col-${size}-${sizes[size]}`]: !!sizes[size]
    })),
    {
      [`col-${sizes.xs}`]: !!sizes.xs,
      'col-auto': !!auto,
      'particles-grid__cell--vertical-gutter': !!verticalGutter
    }
  );
  return (
    <div className={cellClasses} style={style}>
      {children}
    </div>
  );
};

Cell.propTypes = {
  /** Child components */
  children: PropTypes.node,
  /** Additional class to apply to component */
  className: PropTypes.string,
  /** Inline style to add to component */
  style: PropTypes.object,
  /** Automatically size cell */
  auto: PropTypes.bool,
  /** Size of cell for xs screens, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'auto'] */
  xs: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'auto']),
  /** Size of cell for sm screens, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'auto'] */
  sm: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'auto']),
  /** Size of cell for md screens, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'auto'] */
  md: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'auto']),
  /** Size of cell for lg screens, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'auto'] */
  lg: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'auto']),
  /** Size of cell for xl screens, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'auto'] */
  xl: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'auto'])
};

Cell.defaultProps = {
  children: null,
  className: '',
  style: {},
  auto: false,
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0
};

Cell.displayName = 'Grid.Cell';

export default Cell;

export const {
  propTypes,
  defaultProps,
  displayName
} = Cell;
