import _ from 'lodash';

const scrollIntoView = (element) => {
  if (!element) {
    return;
  }
  if (_.isFunction(element.scrollIntoViewIfNeeded)) {
    // chrome - best available behaviour at the moment, only scrolls if needed
    element.scrollIntoViewIfNeeded();
    return;
  }
  if (_.isFunction(element.scrollIntoView)) {
    // other browsers - always scrolls
    element.scrollIntoView();
    return;
  }
};

const scrollToFirst = (selector) => {
  setTimeout(() => {
    const element = document.querySelector(selector);
    scrollIntoView(element);
  }, 1);
};

const exported = {
  scrollToFirst,
  scrollIntoView
};

export default exported;
export { scrollToFirst, scrollIntoView };
