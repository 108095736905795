import context from './context.js';
import Dropdown from './Dropdown.js';
import DropdownGroup from './Group.js';
import DropdownGroupHeader from './GroupHeader.js';
import DropdownItem from './Item.js';
import DropdownMenu from './Menu.js';
import DropdownTrigger from './Trigger.js';

Dropdown.context = context;
Dropdown.Group = DropdownGroup;
Dropdown.Group.Header = DropdownGroupHeader;
Dropdown.GroupHeader = DropdownGroupHeader;
Dropdown.Item = DropdownItem;
Dropdown.Menu = DropdownMenu;
Dropdown.Trigger = DropdownTrigger;

export { Dropdown, DropdownTrigger, DropdownItem, DropdownGroup, DropdownMenu, context };
export default Dropdown;
