import React from 'react';
import classnames from 'classnames';
import { isCheckable } from '../Checkable/index.js';
import { isSelectable } from '../Selectable/index.js';
import PropTypes from 'prop-types';

const Help = (props = {}) => {
  const { className, type, name, children, value } = props;
  const helpClasses = classnames(
    'particles-input__help',
    `particles-input__help--${type}`,
    `particles-input__help--${name}`,
    className,
    {
      'particles-input__help--meta-text': !isCheckable(props) && !isSelectable(props),
      'particles-input__help--meta-selectable': !!isSelectable(props),
      'particles-input__help--meta-checkable': !!isCheckable(props),
      [`particles-input__help--${name}-${value}`]: !!isCheckable(props)
    }
  );
  return (
    <div className={helpClasses}>
      {children}
    </div>
  );
};

Help.displayName = 'Help';
Help.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** Type of element the help belongs to */
  type: PropTypes.string,
  /** `name` attribute value */
  name: PropTypes.string,
  /** Child components */
  children: PropTypes.node,
  value: PropTypes.string
};

export default Help;

export const {
  displayName,
  propTypes
} = Help;
