import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Container = ({ className, noWrap, ...rest }) => {
  const classes = classnames('particles-page__container', {
    'particles-page__container--no-wrap': noWrap
  }, className);
  return (
    <div className={classes} {...rest} />
  );
};

Container.propTypes = {
  /** Child components */
  children: PropTypes.node,
  /** Extra classes */
  className: PropTypes.string,
  /** Do not wrap elements */
  noWrap: PropTypes.bool
};

Container.defaultProps = {
  className: ''
};

Container.displayName = 'Page.Container';

export default Container;

export const {
  propTypes,
  defaultProps,
  displayName
} = Container;
