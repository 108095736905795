import React from 'react';
import _ from 'lodash';
import Tippy from '@tippy.js/react';
import { useMaturityLevel } from '../../component-maturity.js';

const Popover = ({ children, ...rest }) => {
  useMaturityLevel('unstable', 'Popover');
  const safeChildren = _.isString(children) ? <span className="particles-popover__trigger">{children}</span> : children;
  return (
    <Tippy theme="light-border" {...rest} children={safeChildren} className="particles-tooltip particles-popover" />
  );
};

Popover.defaultProps = {
  placement: 'bottom',
  trigger: 'click',
  interactive: true,
  arrow: true
};


export default Popover;
