import React from 'react';
import classnames from 'classnames';
import { scrollToFirst } from '../../../utils/scroll.js';
import PropTypes from 'prop-types';

const Error = (props = {}) => {
  const { className, children } = props;
  const errorClasses = classnames('particles-form__error', className);
  return (
    <div className={errorClasses}>
      <i className="fa fa-exclamation-circle" />{' '}
      {children}
    </div>
  );
};

class ErrorWithScrollIntoView extends React.Component {
  componentDidMount = () => scrollToFirst('.particles-form__error');
  render = () => (<Error {...this.props} />);
}

Error.displayName = 'Error';
Error.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default ErrorWithScrollIntoView;
