import React from 'react';
import classnames from 'classnames';
import { isCheckable } from '../Checkable/index.js';
import { isSelectable } from '../Selectable/index.js';
import PropTypes from 'prop-types';

const Label = (props = {}) => {
  const { className, type, name, value, htmlFor, required, children, disabled } = props;
  const labelClasses = classnames(
    'particles-input__label',
    `particles-input__label--${type}`,
    `particles-input__label--${name}`,
    className,
    {
      'particles-input__label--required': !!required,
      'particles-input__label--disabled': !!disabled,
      'particles-input__label--meta-text': !isCheckable(props) && !isSelectable(props),
      'particles-input__label--meta-selectable': !!isSelectable(props),
      'particles-input__label--meta-checkable': !!isCheckable(props),
      [`particles-input__label--${name}-${value}`]: !!isCheckable(props)
    }
  );
  return (
    <label htmlFor={htmlFor} className={labelClasses}>
      {children}
    </label>
  );
};

Label.displayName = 'Label';
Label.propTypes = {
  /** Extra classes */
  className: PropTypes.string,
  /** Type of element label belongs to */
  type: PropTypes.string,
  /** `name` attribute value */
  name: PropTypes.string,
  /** Value of the `for` attribute of the label*/
  htmlFor: PropTypes.string,
  /** Changes styling to signify required */
  required: PropTypes.bool,
  /** Changes styling to signify disabled */
  disabled: PropTypes.bool,
  /** Child components */
  children: PropTypes.node,
  /** Value of the associated field (only appicable for checkboxes) */
  value: PropTypes.any
};

export default Label;

export const {
  displayName,
  propTypes
} = Label;
