import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TableRow = ({ className, selectable, selected, ...props }) => {
  const classes = classnames('particles-table__table-row', className, {
    'particles-table__table-row--selectable': !!selectable,
    'particles-table__table-row--selected': !!selected
  });
  return <tr className={classes} {...props} />;
};

TableRow.propTypes = {
  /** Adds selectable className when this row can be selected */
  selectable: PropTypes.bool,
  /** Adds selected className when this row is selected */
  selected: PropTypes.bool
};

TableRow.defaultProps = {
  selectable: false,
  selected: false
};

TableRow.displayName = 'Table.Row';

export default TableRow;

export const { propTypes, defaultProps, displayName } = TableRow;
