import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Body = (props) => {
  const { children, className, centered, as: Component, ...rest } = props;
  const bodyClasses = classnames(
    'particles-card__body',
    className,
    {
      'particles-card__body--centered': !!centered
    }
  );
  return (
    <Component className={bodyClasses} {...rest}>
      {children}
    </Component>
  );
};

Body.propTypes = {
  /** Render as this kind of component. ie: [a, Link, Button, ...] */
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.elementType]),
  /** Center content in middle of component */
  centered: PropTypes.bool,
  /** Child components */
  children: PropTypes.node,
  /** Additional class to add to component */
  className: PropTypes.string
};
Body.defaultProps = {
  as: 'div',
  centered: false
};
Body.displayName = 'Card.Body';

export default Body;

export const {
  propTypes,
  defaultProps,
  displayName
} = Body;
