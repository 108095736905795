import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { NavigationTypeContext, useNavigationType } from '../context.js';
import { useMaturityLevel } from '../../../component-maturity.js';

const Text = (props) => {
  useMaturityLevel('stable', 'Navigation.Text');
  const { as: Component, context, className, ...rest } = props;
  const navigationType = useNavigationType(props);
  const textClasses = classnames(
    'particles-navigation-text',
    `particles-navigation-text--${navigationType}`,
    className
  );
  return (
    <NavigationTypeContext {...props}>
      <Component className={textClasses} {...rest} />
    </NavigationTypeContext>
  );
};

Text.defaultProps = {
  as: 'span'
};
Text.propTypes = {
  /** Enable context styling */
  context: PropTypes.bool,
  /** Child components */
  children: PropTypes.node,
  /** Extra classes */
  className: PropTypes.string,
  /** String or function to transform the component into. ie: Button, 'div' */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType])
};
Text.displayName = 'Navigation.Text';

export default Text;

export const {
  defaultProps,
  propTypes,
  displayName
} = Text;
